// import React, { useState } from "react";
import styles from "./ShowReservationWindow.module.scss";
import ModalWindow from "../ModalWindow";
// import {BookingFormType, BookingType } from "../../../redux/types/bookingTypes";
// import BookingHeader from "../../Booking/BookingHeader";
// import BookingPersonBlock from "../../Booking/BookingPersonBlock";
import { useDispatch } from "react-redux";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import { ModalWindowTypes } from "../../../utils/@globalTypes";
// import BookingObjectBlock from "../../Booking/BookingObjectBlock";
// import BookingContactBlock from "../../Booking/BookingContactBlock";
import BookingContainer from "../../Booking/BookingContainer";
import { BookingFormType } from "../../../redux/types/bookingTypes";

const ShowReservationWindow = ({data}:any) => {
  
  const dispatch = useDispatch();
    
  if (!data){
    dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    return null
  }  
  // console.log(data)
  return (
    <ModalWindow isLoading={false} isFullCustom hideCross windowClassname={styles.window}>

      <BookingContainer booking = {data.booking} type ={data.type}/>
      
    </ModalWindow>
  );
};

export default ShowReservationWindow;
