import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { BookingFormType, BookingType, UpdateBookingType } from "../../../redux/types/bookingTypes";
import { removeBooking, updateCurrentBooking } from "../../../redux/reducers/bookingSlice";
import { BookingFormSelectors, completeSave, hasErrors, resetBookingForm, startSave } from "../../../redux/reducers/bookingFormSlice";
import BookingContactBlock from "../BookingContactBlock";
import BookingHeader from "../BookingHeader";
import BookingObjectBlock from "../BookingObjectBlock";
import BookingPersonBlock from "../BookingPersonBlock";
import BookingCommentBlock from "../BookingCommentBlock";

import styles from "./BookingContainer.module.scss";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import { ModalWindowTypes } from "../../../utils/@globalTypes";

type BookingContainerProps={
  booking:BookingType,
  type:BookingFormType
}

const BookingContainer = ({booking, type}:BookingContainerProps) => {
  const dispatch = useDispatch();
  
  const {check_out_date, id} = booking;

  const [isEdit, setIsEdit] = useState(false);   // переключение формы между просмотром и редактированием
  const windowType=isEdit?BookingFormType.edit:BookingFormType.view;
  
  const [isReadyToDelete, setIsReadyToDelete] = useState(false);  //управление активностью кнопки "Удалить"  

  const {
    commentBlockValid, 
    objectBlockValid,
    personBlockValid,
    contactBlockValid,
    isStartSave
  } = useSelector(BookingFormSelectors.getBookingForm);  
    
  const isLast = moment(check_out_date).startOf('day').unix()<moment(new Date()).startOf('day').unix() // является ли бронь прошедшей
  useEffect(()=> {   
    dispatch(resetBookingForm())
  },[])

  useEffect(()=> {  //если бронь прошедшая - сразу можно удалять
    if(isLast){
      setIsReadyToDelete(true)
    }    
  }, [isLast]);

  useEffect(()=> {   //переключение в режим редактирования
    if(type===BookingFormType.edit){      
      setIsEdit(true)
    }    
  }, [type]);
    
  useEffect(()=> {  //если статус брони "отменена" - можно удалять
    if(booking.status==="cld"){
      setIsReadyToDelete(true)
    }    
  }, [booking.status]);

  const handleClickEdit = ()=>{setIsEdit(true)};  //переключение формы в режим редактирования

  const handleClickSave = ()=>{
    console.log("try save")     
    dispatch(startSave())   
  }

  const handleClickDelete = ()=>{
    dispatch(removeBooking(id));    
  };
  console.log(isStartSave)

  useEffect(()=> {
    const {id} = booking;
    if(isStartSave&& objectBlockValid&&personBlockValid&&contactBlockValid&&commentBlockValid){
      if(windowType !== BookingFormType.view &&
        objectBlockValid.isValid===true &&
        personBlockValid.isValid===true &&
        contactBlockValid.isValid===true &&
        commentBlockValid.isValid===true &&
        isStartSave
      ){
        console.log(objectBlockValid.isValid,personBlockValid.isValid,contactBlockValid.isValid,commentBlockValid.isValid  )
        console.log ("Все получилось, можно сохраняться")
        const newBooking:UpdateBookingType = {}
        objectBlockValid.update.rental_object && objectBlockValid.update.rental_object!==booking.rental_object && (newBooking.rental_object=objectBlockValid.update.rental_object);
        objectBlockValid.update.status && objectBlockValid.update.status!==booking.status && (newBooking.status=objectBlockValid.update.status);
        objectBlockValid.update.total && objectBlockValid.update.total!==booking.total && (newBooking.total=objectBlockValid.update.total);
        objectBlockValid.update.paid && objectBlockValid.update.paid!==booking.paid && (newBooking.paid=objectBlockValid.update.paid);
        objectBlockValid.update.has_animals && objectBlockValid.update.has_animals!==booking.has_animals && (newBooking.has_animals=objectBlockValid.update.has_animals);
        objectBlockValid.update.animals_info && objectBlockValid.update.animals_info!==booking.animals_info && (newBooking.animals_info=objectBlockValid.update.animals_info);
        objectBlockValid.update.check_in_date && objectBlockValid.update.check_in_date!==booking.check_in_date && (newBooking.check_in_date=objectBlockValid.update.check_in_date);
        objectBlockValid.update.check_out_date && objectBlockValid.update.check_out_date!==booking.check_out_date && (newBooking.check_out_date=objectBlockValid.update.check_out_date);
        objectBlockValid.update.check_in_time && objectBlockValid.update.check_in_time!==booking.check_in_time && (newBooking.check_in_time=objectBlockValid.update.check_in_time);
        objectBlockValid.update.check_out_time && objectBlockValid.update.check_out_time!==booking.check_out_time && (newBooking.check_out_time=objectBlockValid.update.check_out_time);
        objectBlockValid.update.adult && objectBlockValid.update.adult!==booking.adult && (newBooking.adult=objectBlockValid.update.adult);
        objectBlockValid.update.children && JSON.stringify(objectBlockValid.update.children)!== JSON.stringify(booking.children) && (newBooking.children=objectBlockValid.update.children);
        personBlockValid.update.first_name && personBlockValid.update.first_name!== booking.first_name && (newBooking.first_name=personBlockValid.update.first_name);
        personBlockValid.update.last_name && personBlockValid.update.last_name!== booking.last_name && (newBooking.last_name=personBlockValid.update.last_name);
        personBlockValid.update.second_name && personBlockValid.update.second_name!==personBlockValid.update.second_name && (newBooking.second_name=personBlockValid.update.second_name);
        personBlockValid.update.nationality && personBlockValid.update.nationality!==booking.nationality && (newBooking.nationality=personBlockValid.update.nationality);
        personBlockValid.update.sex && personBlockValid.update.sex!==booking.sex && (newBooking.sex=personBlockValid.update.sex);
        personBlockValid.update.birth_day && personBlockValid.update.birth_day!==booking.birth_day && (newBooking.birth_day=personBlockValid.update.birth_day);
        contactBlockValid.update.email && contactBlockValid.update.email!==booking.email && (newBooking.email=contactBlockValid.update.email);
        contactBlockValid.update.telegram && contactBlockValid.update.telegram!==booking.telegram && (newBooking.telegram=contactBlockValid.update.telegram);
        contactBlockValid.update.phone && JSON.stringify(contactBlockValid.update.phone)!==JSON.stringify(booking.phone) && (newBooking.phone=contactBlockValid.update.phone)
        commentBlockValid.update.comment && commentBlockValid.update.comment!==booking.comment && (newBooking.comment=commentBlockValid.update.comment);      
        console.log(newBooking);
        dispatch(updateCurrentBooking({id, data:newBooking}));
        setIsEdit(false);
        dispatch(completeSave());    
        // dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE })); 
      } else {
          console.log("что-то пошло не так")         
          dispatch(completeSave());
      }
    }   
  }, [isStartSave, objectBlockValid, personBlockValid, contactBlockValid, commentBlockValid]);

  useEffect(()=> {
    const {id} = booking;
    if(windowType === BookingFormType.view && objectBlockValid &&
      objectBlockValid.isValid && isStartSave      
    ){
      console.log ("Все получилось, можно сохраняться")
      const newBooking:UpdateBookingType = {}
      objectBlockValid.update.status && objectBlockValid.update.status!==booking.status && (newBooking.status=objectBlockValid.update.status);
      objectBlockValid.update.total && objectBlockValid.update.total!==booking.total && (newBooking.total=objectBlockValid.update.total);
      objectBlockValid.update.paid && objectBlockValid.update.paid!==booking.paid && (newBooking.paid=objectBlockValid.update.paid);
      console.log(newBooking);
      setIsEdit(false);
      dispatch(updateCurrentBooking({id:id, data:newBooking}));          
      dispatch(completeSave());   
      // dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));    
    }    
  }, [isStartSave, objectBlockValid]);
  
  return (
    <>
      <BookingHeader 
        windowType ={windowType}
        handleClickEdit={handleClickEdit}
        handleClickSave={handleClickSave}   
        handleClickDelete = {handleClickDelete} 
        isLast = {isLast}
        isReadyToDelete={isReadyToDelete}     
        />
        <main className={styles.mainContainer}> 
          <BookingObjectBlock 
            windowType ={windowType} 
            booking={booking} 
            isLast = {isLast}                       
          />
          <BookingPersonBlock 
            windowType ={windowType} 
            booking={booking}                      
          />
          <BookingContactBlock 
            windowType ={windowType} 
            booking={booking}                      
          />
          <BookingCommentBlock
            windowType ={windowType} 
            booking={booking}                        
          />
        </main>
    </>

  )
}

export default BookingContainer ;