import createSagaMiddleware from "@redux-saga/core";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer, { authName } from "./reducers/authSlice";
import rootSaga from "./sagas/rootSaga";
import pageReducer, { pageName } from "./reducers/pageSlice";
import mainObjectReducer, { mainObjectName } from "./reducers/mainObjectSlice";
import rentalObjectReducer, { rentalObjectName } from "./reducers/rentalObjectSlice";
import userSlice, { userName } from "./reducers/userSlice";
import roomSlice, { roomName } from "./reducers/roomSlice";
import bookingSlice, { bookingName } from "./reducers/bookingSlice";
import bookingFormSlice, { bookingFormName } from "./reducers/bookingFormSlice";
import pricesSlice, { pricesName } from "./reducers/pricesSlice";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  [authName]: authReducer,
  [pageName]: pageReducer,
  [mainObjectName]: mainObjectReducer,
  [rentalObjectName]: rentalObjectReducer,
  [userName]: userSlice,
  [roomName]: roomSlice,
  [pricesName]: pricesSlice,
  [bookingName]: bookingSlice,
  [bookingFormName]: bookingFormSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
