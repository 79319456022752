import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SignUp.module.scss";
import FormPage from "../FormPage";
import Input from "../../../components/Input";
import {
  ButtonType,
  FieldTypes,
  InputModeType,
  InputType,
  LoaderTypes,
  TelephoneType,
} from "../../../utils/@globalTypes";
import Button from "../../../components/Button";
import { ArrowIcon } from "../../../assets/icons";
import Checkbox from "../../../components/Checkbox";
import { useNavigate } from "react-router-dom";
import { AuthSelectors, createOwner, setErrorResponeData } from "../../../redux/reducers/authSlice";
import {
  banDoubleSpace,
  getErrorText,
  getFormattedName,
  getInflectedObjectTypes,
  setFieldErrorText,
} from "../../../utils/functions";
import {
  EMAIL_ERROR,
  LAST_NAME_ERROR,
  FIRST_NAME_ERROR,
  FIRST_NAME_REQ,
  OBJECT_NAME_ERROR,
  OBJECT_NAME_REQ,
  PASS_ERROR,
  PASS_REQ,
  REQUIRED_FIELD_ERROR,
  LAST_NAME_REQ,
  TELEPHONE_ERROR,
  URL_ERROR,
  URL_REQ,
} from "../../../utils/constants";
import IntlTelInput from "../../../components/IntlTelInput";
import classNames from "classnames";
import SelectComponent from "../../../components/SelectComponent";
import { MainObjectSelectors } from "../../../redux/reducers/mainObjectSlice";
import { PageSelectors } from "../../../redux/reducers/pageSlice";
import { ObjectTypeData } from "../../../redux/types/mainObjectTypes";
import { RoutesList } from "../../../App";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorResponseSignUpData = useSelector(AuthSelectors.getErrorResponseData);
  const objectTypes = useSelector(MainObjectSelectors.getMainObjectTypesData);
  const isLoading = useSelector(PageSelectors.getLoadersData(LoaderTypes.SIGN_UP_PAGE));

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");

  const [telephone, setTelephone] = useState<TelephoneType>({
    country_code: "",
    phone_code: "",
    phone_number: "",
  });
  const [isTelephoneValid, setIsTelephoneValid] = useState(false);

  const [objectType, setObjectType] = useState("");
  const [objectName, setObjectName] = useState("");
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [statistic, setStatistic] = useState("");
  const [policyState, setPolicyState] = useState(false);

  const [nameError, setNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [objectTypeError, setObjectTypeError] = useState("");
  const [objectNameError, setObjectNameError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [nameTouched, setNameTouched] = useState(false);
  const [surnameTouched, setSurnameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [telephoneTouched, setTelephoneTouched] = useState(false);
  const [objectTypeTouched, setObjectTypeTouched] = useState(false);
  const [objectNameTouched, setObjectNameTouched] = useState(false);
  const [websiteTouched, setWebsiteTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);

  const [currentObjTypeData, setCurrentObjTypeData] = useState<ObjectTypeData>({
    id: 0,
    title: "Объект",
    example: "Введите название объекта",
  });

  const [page, setPage] = useState(1);

  const onNextBtnClick = () => {
    setNameTouched(true);
    setSurnameTouched(true);
    setEmailTouched(true);
    setTelephoneTouched(true);
    setObjectTypeTouched(true);

    if (!nameError && !surnameError && !emailError && !telephoneError && !objectTypeError) {
      setPage(2);
    }
  };

  const onBackBtnClick = () => {
    setPage(1);
  };

  const onSignUpBtnClick = () => {
    setObjectNameTouched(true);
    setWebsiteTouched(true);
    setPasswordTouched(true);
    setConfirmPasswordTouched(true);

    if (!websiteError && !passwordError && !confirmPasswordError && !objectNameError) {
      dispatch(
        createOwner({
          data: {
            createOwnerData: {
              email,
              password,
              first_name: getFormattedName(name),
              last_name: getFormattedName(surname),
              phone: telephone.phone_number ? telephone : undefined,
              website: website ? website : undefined,
              object_title: objectName.trim(),
              object_type: +objectType,
            },
            statistic: statistic ? statistic : undefined,
          },
          callback() {
            navigate(RoutesList.signIn.fullPath);
          },
        })
      );
    }
  };

  useEffect(() => {
    const currentObjectType = objectTypes.fullData.find((item) => item.id === +objectType);
    if (currentObjectType) {
      setCurrentObjTypeData(currentObjectType);
    }
  }, [objectTypes, objectType]);

  // const handleKeyDown = (event: KeyboardEvent) => {
  //   if (event.key === "Enter") {
  //     if (isValidFirst && page === 1) {
  //       onNextBtnClick();
  //     } else if (isValidFirst && isValidSecond && page === 2) {
  //       onSignUpBtnClick();
  //     }
  //   }
  // };

  useEffect(() => {
    if (errorResponseSignUpData) {
      errorResponseSignUpData.first_name &&
        setNameError(
          getErrorText(
            errorResponseSignUpData.first_name[0],
            FIRST_NAME_ERROR,
            FieldTypes.FIRST_NAME
          )
        );

      errorResponseSignUpData.last_name &&
        setSurnameError(
          getErrorText(errorResponseSignUpData.last_name[0], LAST_NAME_ERROR, FieldTypes.LAST_NAME)
        );

      errorResponseSignUpData.email &&
        setEmailError(
          getErrorText(errorResponseSignUpData.email[0], EMAIL_ERROR, FieldTypes.EMAIL)
        );

      errorResponseSignUpData.phone_number &&
        setTelephoneError(
          getErrorText(
            errorResponseSignUpData.phone_number[0],
            TELEPHONE_ERROR,
            FieldTypes.TELEPHONE
          )
        );

      Array.isArray(errorResponseSignUpData) &&
        errorResponseSignUpData[0] === "Main object type doesn't exist" &&
        setObjectTypeError(REQUIRED_FIELD_ERROR);

      errorResponseSignUpData.object_title &&
        setObjectNameError(
          getErrorText(
            errorResponseSignUpData.object_title[0],
            OBJECT_NAME_ERROR,
            FieldTypes.OBJECT_NAME
          )
        );

      errorResponseSignUpData.website &&
        setWebsiteError(
          getErrorText(errorResponseSignUpData.website[0], URL_ERROR, FieldTypes.URL)
        );

      errorResponseSignUpData.password &&
        setPasswordError(
          getErrorText(errorResponseSignUpData.password[0], PASS_ERROR, FieldTypes.PASSWORD)
        );

      dispatch(setErrorResponeData(null));
    }
  }, [errorResponseSignUpData]);

  useEffect(() => {
    if (nameError || surnameError || emailError || telephoneError) {
      setPage(1);
    }
  }, [nameError, surnameError, emailError, telephoneError]);

  // Name

  useEffect(() => {
    setFieldErrorText(nameTouched, name, setNameError, FieldTypes.FIRST_NAME, true);
  }, [nameTouched, name]);

  // Surname

  useEffect(() => {
    setFieldErrorText(surnameTouched, surname, setSurnameError, FieldTypes.LAST_NAME, true);
  }, [surnameTouched, surname]);

  // Email

  useEffect(() => {
    setFieldErrorText(emailTouched, email, setEmailError, FieldTypes.EMAIL, true);
  }, [emailTouched, email]);

  // Telephone

  useEffect(() => {
    setFieldErrorText(
      telephoneTouched,
      telephone.phone_number,
      setTelephoneError,
      FieldTypes.TELEPHONE,
      true,
      isTelephoneValid
    );
  }, [telephoneTouched, telephone.phone_number, isTelephoneValid]);

  // ObjectType

  useEffect(() => {
    setFieldErrorText(objectTypeTouched, objectType, setObjectTypeError, FieldTypes.DEFAULT, true);
  }, [objectTypeTouched, objectType]);

  // ObjectName

  useEffect(() => {
    setFieldErrorText(
      objectNameTouched,
      objectName,
      setObjectNameError,
      FieldTypes.OBJECT_NAME,
      true
    );
  }, [objectNameTouched, objectName]);

  // Website

  useEffect(() => {
    setFieldErrorText(websiteTouched, website, setWebsiteError, FieldTypes.URL, false);
  }, [websiteTouched, website]);

  // Password validation

  useEffect(() => {
    setFieldErrorText(passwordTouched, password, setPasswordError, FieldTypes.PASSWORD, true);
  }, [passwordTouched, password]);

  useEffect(() => {
    setFieldErrorText(
      confirmPasswordTouched,
      confirmPassword,
      setConfirmPasswordError,
      FieldTypes.CONFIRM_PASSWORD,
      true,
      password !== confirmPassword
    );
  }, [confirmPassword, password, confirmPasswordTouched]);

  const isValidFirst = useMemo(() => {
    return (
      !nameError &&
      !surnameError &&
      !emailError &&
      !telephoneError &&
      !objectTypeError &&
      name.length > 0 &&
      surname.length > 0 &&
      email.length > 0 &&
      objectType.length > 0
    );
  }, [
    nameError,
    surnameError,
    emailError,
    telephoneError,
    objectTypeError,
    name,
    surname,
    email,
    objectType,
  ]);

  const isValidSecond = useMemo(() => {
    return (
      !websiteError &&
      !passwordError &&
      !confirmPasswordError &&
      !objectNameError &&
      objectName.length > 0 &&
      password.length > 0 &&
      confirmPassword.length > 0 &&
      policyState
    );
  }, [
    websiteError,
    passwordError,
    confirmPasswordError,
    objectNameError,
    objectName,
    password,
    confirmPassword,
    policyState,
  ]);

  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [isValidFirst, isValidSecond, page, objectName, website, password, policyState]);

  return (
    <FormPage
      pageTitle="Регистрация"
      description="Пожалуйста, введите данные:"
      isLoading={isLoading}
    >
      <div
        className={classNames({
          [styles.formHide]: page === 2,
        })}
      >
        <div className={styles.inputsWrapper}>
          <Input
            title="Имя*"
            value={name}
            placeholder="Введите имя"
            type={InputType.TEXT}
            onChange={setName}
            onBlur={setNameTouched}
            errText={nameError}
            requirementsText={FIRST_NAME_REQ}
            banSymbols={/\s/g}
            autoComplete="given-name"
          />
          <Input
            title="Фамилия*"
            value={surname}
            placeholder="Введите фамилию"
            type={InputType.TEXT}
            onChange={setSurname}
            onBlur={setSurnameTouched}
            errText={surnameError}
            requirementsText={LAST_NAME_REQ}
            banSymbols={/\s/g}
            autoComplete="family-name"
          />
          <Input
            title="Электронная почта*"
            value={email}
            name="email"
            placeholder="Введите электронную почту"
            type={InputType.TEXT}
            inputMode={InputModeType.EMAIL}
            onChange={setEmail}
            onBlur={setEmailTouched}
            errText={emailError}
            banSymbols={/\s/g}
            autoComplete="email"
          />
          <IntlTelInput
            id="signUpMobilePhone"
            title="Мобильный телефон"
            value={telephone}
            placeholder="Введите номер телефона"
            onChange={setTelephone}
            errText={telephoneError}
            setError={setTelephoneError}
            onBlur={setTelephoneTouched}
            setIsValidate={setIsTelephoneValid}
          />
          <SelectComponent
            title="Тип объекта размещения*"
            optionsList={objectTypes.optionsList}
            currentValue={objectType}
            setSelecValue={setObjectType}
            errText={objectTypeError}
            onBlur={setObjectTypeTouched}
            isLoading={objectTypes.optionsList.length === 0}
          />
        </div>
        <Button
          className={styles.nextBtn}
          title="Далее"
          type={ButtonType.PRIMARY}
          onClick={onNextBtnClick}
          disabled={!isValidFirst}
        />
      </div>
      <div
        className={classNames({
          [styles.formHide]: page === 1,
        })}
      >
        <div className={styles.backBtn} onClick={onBackBtnClick}>
          <ArrowIcon />
          <span>Назад</span>
        </div>
        <div className={styles.inputsWrapper}>
          <Input
            title={`Название ${getInflectedObjectTypes(currentObjTypeData.title)}*`}
            value={objectName}
            placeholder={currentObjTypeData.example}
            type={InputType.TEXT}
            onChange={setObjectName}
            onBlur={setObjectNameTouched}
            errText={objectNameError}
            requirementsText={OBJECT_NAME_REQ}
            customBanSymbols={banDoubleSpace}
          />
          <Input
            title="Адрес сайта"
            value={website}
            name="url"
            placeholder="https://example.com"
            type={InputType.TEXT}
            inputMode={InputModeType.URL}
            onBlur={setWebsiteTouched}
            onChange={setWebsite}
            errText={websiteError}
            banSymbols={/\s/g}
            autoComplete="url"
            requirementsText={URL_REQ}
          />
          <Input
            title="Пароль*"
            name="pass"
            value={password}
            placeholder="Введите пароль"
            type={InputType.PASSWORD}
            onChange={setPassword}
            onBlur={setPasswordTouched}
            errText={passwordError}
            autoComplete="new-password"
            requirementsText={PASS_REQ}
            addHiddenInput
          />
          <Input
            title="Повторить пароль*"
            name="confirmPass"
            value={confirmPassword}
            placeholder="Введите пароль"
            type={InputType.PASSWORD}
            onChange={setConfirmPassword}
            onBlur={setConfirmPasswordTouched}
            autoComplete="new-password"
            // isHideBrowserAutoCompleteMenu
            errText={confirmPasswordError}
          />
          <Input
            title="Как вы о нас узнали?"
            value={statistic}
            placeholder="Напишите откуда вы о нас узнали"
            type={InputType.TEXT}
            onChange={setStatistic}
          />
          <Checkbox
            checked={policyState}
            onChange={setPolicyState}
            className={styles.checkbox}
            name="personalData"
            label={
              <>
                Даю согласие Vitaem и лицам, действующим по его поручению, на обработку моих
                персональ-ных данных в соответствии с
                <span className={styles.policy}> Политикой</span>
              </>
            }
          />
        </div>
        <Button
          className={styles.nextBtn}
          title="Зарегистрироваться"
          type={ButtonType.PRIMARY}
          onClick={onSignUpBtnClick}
          disabled={!isValidSecond}
        />
      </div>
    </FormPage>
  );
};

export default SignUp;
