import { useEffect, useLayoutEffect, useState } from "react";
import classNames from "classnames";
import { BookingFormType, BookingStatus, BookingType, UpdateBookingType } from "../../../redux/types/bookingTypes";
import SelectComponent from "../../SelectComponent";
import BookingSectionTitle from "../BookingSectionTitle";
import PersonCount from "../PersonCount";
import InputSum from "../InputSum";
import { CURRENCY } from "./../../../utils/constants";
import {subMonths, addYears, addDays, differenceInDays} from 'date-fns';
import { createObjectList, statusListOption, timeListOption,formatDate } from "../functions";
// import { objects } from "../../CheckerboardTable/fakeData";
// import { rental_obj } from "./datas";
import Input from "../../Input";
import { useDispatch } from "react-redux";

import { ButtonType, InputType, OptionsListType } from "../../../utils/@globalTypes";
import Checkbox from "../../Checkbox";
import Tooltip from "../../Tooltip";
import Calendar from "../../Calendar";
import BookingLineTitle from "../BookingLineTitle";
import styles from "./BookingObjectBlock.module.scss";
import SelectComponentV2 from "../../SelectComponentV2";
import { Arrow2Icon } from "../../../assets/icons";
import {
  REQUIRED_FIELD_ERROR, 
  TOTAL_INFO_ERROR, 
  ANIMALINFO_REQ
} from "./../../../utils/constants";
import { getCurrentRentalObject, RentalObjectSelectors } from "../../../redux/reducers/rentalObjectSlice";
import { CurrentRentalObject } from "../../../redux/types/rentalObjectTypes";
import { useSelector } from "react-redux";
import { BookingSelectors } from "../../../redux/reducers/bookingSlice";
import { BookingFormSelectors, completeErrors, completeSave, hasErrors, setObjectBlockValid, startEdit } from "../../../redux/reducers/bookingFormSlice";
import Button from "../../Button";

type ObjectblockProps ={
  windowType?:BookingFormType,
  booking?:BookingType,
  isLast?:boolean    
}
const BookingObjectBlock = ({
    windowType=BookingFormType.view, 
    booking,
    isLast=false   
}:ObjectblockProps) => {

  const dispatch = useDispatch();
  const objects = useSelector(BookingSelectors.getChessObjects);   
  const {
    isActiveSaveButton,
    isStartSave,
    isHasErrors,
    objectBlockValid
  } = useSelector(BookingFormSelectors.getBookingForm)   
  
  const [object, setObject]= useState(""); 
  const [objectTouched, setObjectTouched]= useState(false);
  const [objectError, setObjectError] = useState("");

  const [currentObject, setCurrentObject] = useState<CurrentRentalObject|null>(null);

  const optionList = objects?createObjectList(objects):[];
  const statusListOptionForCreation:OptionsListType = statusListOption.filter(item=>item.value!=="cld");  

  const [checkInDate, setCheckInDate] = useState<Date|undefined>();
  const [checkInTime, setCheckInTime] = useState<string>("");
  const [checkOutTime, setCheckOutTime] = useState<string>("");
  const [checkOutDate, setCheckOutDate] = useState<Date|undefined>();
  const [timeList, setTimeList] = useState(timeListOption);
  
  const [checkIndateError, setcheckIndateError] = useState("");
  const [checkOutdateError, setcheckOutdateError] = useState("");
  const [checkIndateTouched, setcheckIndateTouched] = useState(false);
  const [checkOutdateTouched, setcheckOutdateTouched] = useState(false);

  const [checkIntimeError, setcheckIntimeError] = useState("");
  const [checkIntimeTouched, setcheckIntimeTouched] = useState(false);

  const [status, setStatus]= useState<BookingStatus>();
  const [statusTouched, setStatusTouched] = useState(false);

  const initialSumm = "0.00"

  const [total, setTotal]= useState(initialSumm);
  const [summTouched, setSummTouched] = useState(false);  

  const [paid, setPaid]= useState(initialSumm);
  const [paidError, setPaidError] = useState("");

  const [rest, setRest]= useState(initialSumm);
  const [restError, setRestError] = useState("");

  const [hasAnimals, setHasanimals] = useState(false);
  const [animalInfo, setAnimalInfo] = useState("");   
  const [animalInfoTouched, setAnimalInfoTouched] = useState(false); 
  const [animalInfoError, setAnimalInfoError] = useState("");

  const [adultCount, setAdultcount]=useState(1);
  const [kidsCount, setKidscount]=useState<number[]>([]);

  const [isObjectFocus, setObjectFocus] = useState(false);
  const [isCheckinDateFocus, setCheckinDateFocus] = useState(false);
  const [isCheckoutDateFocus, setCheckoutDateFocus] = useState(false);
  
  const rental_object = useSelector(RentalObjectSelectors.getCurrentRentalObject)
  
  useLayoutEffect(() => {  
    if(booking){
      const {
        rental_object,
        status,
        total,
        paid,
        has_animals,
        animals_info,
        check_in_date,
        check_out_date,
        check_in_time,
        check_out_time,
        adult,
        children              
      } = booking; 
      rental_object !== null && setObject(rental_object.toString());    
      status !== null && setStatus(status);
      total !== null && setTotal(total);
      paid !== null && setPaid(paid);
      setHasanimals(has_animals); 
      animals_info && setAnimalInfo(animals_info);
      check_in_date !== null && setCheckInDate(new Date(check_in_date)) 
      check_out_date !== null && setCheckOutDate(new Date(check_out_date)) 
      check_in_time !== null && setCheckInTime(check_in_time);
      check_out_time !== null && setCheckOutTime(check_out_time);
      setRest((Number(total)-Number(paid)).toString())
      adult&&setAdultcount(adult)    
      children&&setKidscount(children)
    }
  }, [booking]);     
  useEffect(()=> {
    if(status && (booking?.status!== status)){      
      setStatusTouched(true)
    }
  },[status])
  const recountRest = () => {  // функция пересчет остатка суммы к оплате 
    
    if (!total) setTotal(initialSumm);
    if (!paid) setPaid(initialSumm);
    const summ = (Number(total)-Number(paid)).toFixed(2).toString();
    
    setRest(summ);
    if((Number(total)-Number(paid))<0){
      setRestError("ошибка")
    }else {
      setRestError("")
    }
  }
  useEffect(()=> {  // пересчет остатка суммы к оплате
    recountRest();
    
  },[total, paid])  

  useEffect(()=> {  
    if(total!==initialSumm && (Number(total) !== Number(booking?.total))){
        setSummTouched(true)
    }    
  },[total])  

  useEffect(()=> {  
    if(paid!==initialSumm && (Number(paid) !== Number(booking?.paid))){
        setSummTouched(true)
    }    
  },[paid])  


  useEffect(()=> {
    if(object){
      const id = Number(object)
      dispatch(getCurrentRentalObject(id));    
    }
  }, [object]);//

  useEffect(()=> {
    setCurrentObject(rental_object)
  }, [rental_object]);//

  useEffect(()=> {
    if(currentObject){
      setCheckOutTime(currentObject.booking_setting.check_out)
      if(!booking){
        setCheckInTime(currentObject.booking_setting.check_in)
      }
      updateTimeListOption()
    }    
  }, [currentObject]);
  
  const updateTimeListOption =()=> {    // создаем список времени заезда
    const index = timeListOption.findIndex(item=> item.value===checkInTime)
    if (index>0){
      const newList =timeListOption.slice(index, timeListOption.length)
      setTimeList(newList)
    } else setTimeList(timeListOption)
  }
  useEffect(()=> {
    if(checkOutdateTouched){
      if (!checkOutDate){
        setcheckOutdateError(REQUIRED_FIELD_ERROR)
      }else {setcheckOutdateError("")}
    }    
  }, [checkOutdateTouched, checkOutDate]);

  useEffect(()=> {
    if(checkIntimeTouched){
      if (!checkInTime){
        setcheckIntimeError(REQUIRED_FIELD_ERROR)
      }else {setcheckIntimeError("")}
    }    
  }, [checkIntimeTouched, checkInTime]);
  
  useEffect(()=> {
    if(checkOutDate && checkInDate){
      if(checkOutDate === checkInDate){
        setcheckOutdateError("Дата выезда не может быть равна дате заезда")
      }
      if (checkOutDate < checkInDate){
        setcheckOutdateError("Дата выезда не может быть раньше даты заезда")
      }
      if (checkOutDate > checkInDate){
        if(differenceInDays(checkOutDate,checkInDate)>180){
          setcheckOutdateError("Бронь не может превышать 180 дней")          
        }else{
          setcheckOutdateError("")
        }
      } 
    }
  }, [checkOutDate, checkInDate]);  

  useEffect(()=> {
    if(objectTouched){
      if (!object){
        setObjectError(REQUIRED_FIELD_ERROR)
      }else {setObjectError("")}
    }    
  }, [objectTouched, object]);
  
  
  useEffect(()=> {
    if((Number(total)-Number(paid))<0){
      setPaidError("Сумма оплаты не может превышать общую стоимость")      
      }else {
        setPaidError("");       
    }    
  }, [total, paid]);
  
  const currentYear = new Date().getFullYear();
  const prevYear = currentYear-1
  
  const handleClickSumm =() => {  // пересчет суммы брони при клике на кнопку
    if(checkInDate &&
      checkOutDate &&
      currentObject &&
      !checkIndateError&&
      !checkOutdateError  
    ){     
      setSummTouched(true)
      let startHighSeason = currentObject.price&&new Date(currentObject.price?.start_date);   // добавляем года к настройкам начала и конца высокого сезона  
      let endHighSeason = currentObject.price&&new Date(currentObject.price?.end_date);
      let anotheYearStartHighSeason:Date;
      let anotheYearEndHighSeason:Date;
      
      if (startHighSeason&&endHighSeason){      
        if(startHighSeason<endHighSeason){
          startHighSeason.setFullYear(currentYear);
          endHighSeason.setFullYear(currentYear);
          
        } 
        if(startHighSeason>endHighSeason){
          startHighSeason.setFullYear(prevYear);
          endHighSeason.setFullYear(currentYear);          
        }
        anotheYearStartHighSeason=addYears(startHighSeason, 1)
        anotheYearEndHighSeason=addYears(endHighSeason, 1)
      }
      
      const period:Date[]=[];     // массив из дат, входящих в бронь   
      for(let i=checkInDate; i<checkOutDate; i=addDays(new Date(i),1)){      
        period.push(i)            
      }
    let summ:number = 0;  // общая сумма
    period.forEach(item=>{   //считаем стоимость каждого дня
      let priceDay = 0
      const day = item.getDay();
      if (day===5||day===6){  //   проверка на выходной день
        priceDay= priceDay+Number(currentObject.price?.weekend_price)
      } else priceDay= priceDay+Number(currentObject.price?.weekday_price)

      if(startHighSeason&&endHighSeason){    
        if ((item>=startHighSeason && item<=endHighSeason)||(item>=anotheYearStartHighSeason && item<=anotheYearEndHighSeason)){
          priceDay=priceDay*1
        } else {   // будет ли скидка в низкий сезон
          priceDay = priceDay-priceDay*Number(currentObject.price?.low_season_discount)/100
         }
      }
      if(currentObject.certain_day_prices && currentObject.certain_day_prices.length>0){
        currentObject.certain_day_prices.forEach(certItem=>{
          if(item>=new Date(certItem.start_date) && item<=new Date(certItem.end_date)){
            priceDay=Number(certItem.price)
          }
        })
      }
      summ= summ+priceDay
    })
    setTotal(summ.toFixed(2).toString())
    }
  }
  
  useEffect(()=> {
    if(checkIndateTouched){
      if (!checkInDate){
        setcheckIndateError(REQUIRED_FIELD_ERROR)
      }else {setcheckIndateError("")}
    }    
  }, [checkIndateTouched, checkInDate]);

  

  useEffect(()=> {
    if(animalInfoTouched){
      if (animalInfo.length>100){
        setAnimalInfoError(TOTAL_INFO_ERROR)
      }else {setAnimalInfoError("")}
    }    
  }, [animalInfoTouched, animalInfo]);  
  
  const animalInfoView = !hasAnimals? "Нет": animalInfo;
  const today = new Date();
  const startCalendar = subMonths(new Date(), 6);
  const endCalendar = addYears(new Date(), 1); 

  useEffect(()=> {     //проверяем на изменение данные
    if((
      objectTouched||checkIndateTouched||checkOutdateTouched||checkIntimeTouched||statusTouched||summTouched||animalInfoTouched)&& 
      (booking?.rental_object!==Number(object) || 
      (checkInDate&&(booking.check_in_date!== formatDate(checkInDate))) || 
      (checkOutDate&&(booking.check_out_date!== formatDate(checkOutDate)))|| 
      booking.check_in_time !== checkInTime || 
      booking.status!== status ||
      booking.total !== total ||
      booking.paid !== paid ||
      booking.has_animals !== hasAnimals||
      booking.animals_info !== animalInfo||
      booking.adult !== adultCount||
      JSON.stringify(booking.children) !== JSON.stringify(kidsCount)
    )&& !isActiveSaveButton){      
      dispatch(startEdit())
    }    
  }, [objectTouched, checkIndateTouched, checkOutdateTouched,checkIntimeTouched, statusTouched, summTouched,animalInfoTouched, kidsCount, adultCount]);  

  useEffect(()=> {
    if(isStartSave){
      tryValidBlock();      
    }
  }, [isStartSave]);

    const tryValidBlock=()=>{
    setcheckIndateTouched(true);
    setcheckOutdateTouched(true);
    setObjectTouched(true);
    setcheckIntimeTouched(true);
    setAnimalInfoTouched(true);
    recountRest();
    if(
      !checkIndateError&&
      !checkOutdateError&&
      !objectError&&
      !animalInfoError&&
      !paidError&&
      !restError&&
      !!status&&
      !!object&&
      !!checkInDate&&
      !!checkOutDate&&
      !!checkInTime&&
      !!checkOutTime
      
    ){
      if(windowType === BookingFormType.edit&&booking){ 
        const newObjectData:UpdateBookingType ={};       
        booking.rental_object!==Number(object)&& (newObjectData.rental_object = Number(object));
        booking.status!==status && (newObjectData.status = status);
        Number(booking.total)!==Number(total) && (newObjectData.total = total);
        Number(booking.paid)!== Number(paid) && (newObjectData.paid = paid);
        booking.has_animals!== hasAnimals && (newObjectData.has_animals = hasAnimals);
        booking.animals_info !== animalInfo && (newObjectData.animals_info = animalInfo);
        booking.check_in_date !== formatDate(checkInDate) && (newObjectData.check_in_date = formatDate(checkInDate));
        booking.check_out_date !== formatDate(checkOutDate) && (newObjectData.check_out_date = formatDate(checkOutDate));
        booking.check_in_time !== checkInTime && (newObjectData.check_in_time = checkInTime);
        booking.check_out_time !== checkOutTime && (newObjectData.check_out_time = checkOutTime);
        booking.adult !== adultCount && (newObjectData.adult = adultCount);
        JSON.stringify(booking.children) !== JSON.stringify(kidsCount) && (newObjectData.children = kidsCount ) 
        return dispatch(setObjectBlockValid({isValid:true, update:newObjectData}))       
      }
      if(windowType === BookingFormType.view&&booking){  
        const newObjectData:UpdateBookingType ={};      
        booking.status!==status && (newObjectData.status = status);
        booking.total!==total && (newObjectData.total = total);
        booking.paid!== paid && (newObjectData.paid = paid);
        return dispatch(setObjectBlockValid({isValid:true, update:newObjectData})) 
      }
      if(windowType === BookingFormType.new){ 
        const newObjectData:UpdateBookingType ={};       
        newObjectData.rental_object = Number(object);
        newObjectData.status = status;
        newObjectData.total = total;
        newObjectData.paid = paid;
        newObjectData.has_animals = hasAnimals;
        newObjectData.animals_info = animalInfo;
        newObjectData.check_in_date = formatDate(checkInDate);
        newObjectData.check_out_date = formatDate(checkOutDate);
        newObjectData.check_in_time = checkInTime;
        newObjectData.check_out_time = checkOutTime;
        newObjectData.adult = adultCount;
        newObjectData.children = kidsCount;

       dispatch(setObjectBlockValid({isValid:true, update:newObjectData}))       
      }
      
    } else{      
        dispatch(setObjectBlockValid({isValid:false, update:{}}));
        dispatch(hasErrors())
      
    }
  }
  useEffect(() => {
    if(isHasErrors && !objectBlockValid?.isValid &&isStartSave){
      scrollToError();
    }
  }, [isHasErrors,objectBlockValid, isStartSave]);

  const scrollToError = ()=>{
    console.log("старт скролла")
    if(objectError) {
        setObjectFocus(true);
      // return
    } else if(checkIndateError){      
        setCheckinDateFocus(true)
      // return
    } else if(checkOutdateError){       
        setCheckoutDateFocus(true)
      // return
    
    dispatch(completeSave());
    dispatch(completeErrors())
    }
  }
  return (
    <div className={styles.wrap}>
      <BookingSectionTitle title="Информация об объекте"/>
      <div className={styles.container}>
        <div className={styles.line}>
          <div className={styles.column}>
            <SelectComponent
                title={windowType===BookingFormType.view ? "Объект" : "Объект*"}
                optionsList={optionList}
                currentValue={object}
                isSearchable={windowType===BookingFormType.view ? false : true}
                isDisabled={windowType===BookingFormType.view||isLast ? true : false}
                setSelecValue={setObject}
                isBooking={true}
                onBlur={setObjectTouched} 
                errText={objectError} 
                isFocus={isObjectFocus}             
              />
          </div>
          <div className={styles.column}>
            <SelectComponent
                  title={isLast ? "Статус":"Статус*"}
                  optionsList={windowType===BookingFormType.new ? statusListOptionForCreation : statusListOption}
                  currentValue={status||""}
                  isSearchable={true}
                  setSelecValue={setStatus}
                  isBooking={true}
                  onBlur={setStatusTouched}
                  defaultValueId={windowType===BookingFormType.new ? 0: undefined}
                  isDisabled={isLast ? true : false}
                />
          </div>
        </div>        
        
        <div className={styles.line}>
          <div className={classNames(styles.column, styles.dateBlock, windowType!==BookingFormType.view&&styles.edit)}>
            <div>
              <BookingLineTitle title={windowType===BookingFormType.view ? "Дата заезда" : "Дата заезда*"}/>
              <Calendar
                disabled={windowType===BookingFormType.view ? true: false }
                placeholderText="Выберите дату заезда"
                selectedDate={checkInDate}
                onChange={setCheckInDate}
                isSmall={true}     
                minDate={windowType===BookingFormType.edit ? startCalendar : today} 
                maxDate={endCalendar}     
                isError={checkIndateError?true:false}     
                onBlur={setcheckIndateTouched} 
              />
              {checkIndateError&&<span className={styles.errorText}>{checkIndateError}</span>}
            </div>
            <div className={styles.out}>
              <BookingLineTitle title={windowType===BookingFormType.view ? "Дата выезда" : "Дата выезда*"}/>
              <Calendar
                  disabled={windowType===BookingFormType.view ? true: false }
                  placeholderText="Выберите дату выезда"
                  selectedDate={checkOutDate}
                  onChange={setCheckOutDate}
                  isSmall={true} 
                  minDate={(checkInDate&&addDays(checkInDate,1)) || today}  
                  maxDate={endCalendar}   
                  isError={checkOutdateError?true:false} 
                  onBlur={setcheckOutdateTouched}       
                />
                {checkOutdateError&&<span className={styles.errorText}>{checkOutdateError}</span>}
              </div>
          </div>
          <div className={classNames(styles.column, styles.dateBlock, windowType!==BookingFormType.view&&styles.edit)}>
          <div>
              <BookingLineTitle title={windowType===BookingFormType.view ? "Время заезда" : "Время заезда*"}/>
              {windowType===BookingFormType.view && 
                <div className={styles.viewTime}>
                  {checkInTime &&"c "+ checkInTime}
                </div>}
              {windowType!==BookingFormType.view && 
              <SelectComponentV2
                title={checkInTime? checkInTime: '-'}
                placeholder=""
                currentValue={checkInTime}
                setSelecValue={setCheckInTime}
                optionsList={timeList}
                onBlur={setcheckIntimeTouched}
                errText={checkIntimeError}
                isBooking
                isTime
              />
              }  
            </div>
            <div className={styles.out}>
              <BookingLineTitle title="Время выезда"/>
              {windowType===BookingFormType.view && 
                <div className={styles.viewTime}>
                  {checkOutTime &&"до "+ checkOutTime}
                </div>
              }                
              {windowType!==BookingFormType.view && 
                <div className={styles.editTime}>
                  <div className={styles.leftTime}>
                    {checkOutTime &&checkOutTime}
                  </div>
                  <div className={styles.rigthTime}>
                    <span>-</span>
                    <Arrow2Icon/>
                  </div>
                </div>
              }                
              </div>
          </div>
        </div>
        <div className={styles.column}>
          <PersonCount 
            adult={adultCount||1} 
            children={kidsCount }
            windowType={windowType}
            setAdult = {setAdultcount}
            setKids = {setKidscount}
          />
        </div>
        <div className={styles.animalBlock}>
          <div className={styles.animalLine}>
            <span className={classNames(styles.animalTitle, windowType===BookingFormType.view&&styles.disabled)}>
              Есть ли животные? 
            </span>
            {windowType!==BookingFormType.view&&
            <div className={styles.checkbox}>
              <Checkbox
                checked={hasAnimals}
                label=""
                name="has_animals"
                onChange={setHasanimals}            
              />
              </div>}
            {windowType!==BookingFormType.view&&
            <div className={styles.tooltip}>
              <Tooltip
                requirementsText={ANIMALINFO_REQ}  
                error={!!animalInfoError}          
              />
            </div>}
          </div>
          {windowType===BookingFormType.view&&
            <div className={styles.animalInfo}>
              {animalInfoView}
            </div>
          }
          {windowType!==BookingFormType.view&&hasAnimals&&
            <Input
              type={InputType.TEXTAREA}
              value={animalInfo}
              placeholder=""
              onChange={setAnimalInfo}
              isBooking={true}
              onBlur={setAnimalInfoTouched}
              errText={animalInfoError}
              maxLength={100}
            />
          }
        </div>
        
        <div className={styles.priceBlock}>
          <div className={styles.summBlock}>
            <div className={styles.totalLine}>
              <span className={styles.totalTitle}>
                Общая стоимость за выбранный период*
              </span>
              <div className={styles.inputsumm}>              
                <InputSum 
                  summ={total} 
                  onChange={setTotal}
                  onBlur={recountRest}
                  min={0}    
                  max={999999999.99}          
                />            
              </div>
              <span className={styles.currency}>
                {CURRENCY}
              </span>
            </div>
         
              <ul className={styles.list} >
                <li className={styles.payLine}>
                  <span className={styles.marker}>&#8226;</span>
                  <span className={styles.payTitle}>
                    Оплаченная сумма*
                  </span>
                  <span className={styles.inputsumm}>              
                    <InputSum 
                      summ={paid} 
                      onChange={setPaid}                  
                      onBlur={recountRest}
                      min={0}
                      max={999999999.99}
                      isError={paidError?true:false}
                    />
                  </span>
                  <span className={styles.currency}>
                  {CURRENCY}
                  </span>
                
                </li>
                {paidError&&<span className={classNames(styles.errorText, styles.summError)}>{paidError}</span>}
                <li className={styles.payLine}>
                  <span className={styles.marker}>&#8226;</span>
                  <span className={styles.payTitle}>
                  Сумма, которая осталась к оплате
                  </span>
                  <span className={styles.inputsumm}>              
                    <InputSum 
                      summ={rest} 
                      isDisabled={true}
                      min={-999999999.99}
                      max={999999999.99}
                      isError={restError?true:false}
                    />
                  </span>
                  <span className={styles.currency}>
                  {CURRENCY}
                  </span>
                </li>
              </ul>

          </div>
          <div className={styles.btnSumm}>
            <Button 
                title="Пересчитать" 
                type={ButtonType.PRIMARY_SMALL} 
                onClick={handleClickSumm}
                disabled= {windowType===BookingFormType.view ? true: false}
              />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingObjectBlock ;