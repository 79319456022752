import React, { FC, useRef, useState } from "react";
import styles from "./Tooltip.module.scss";
import { TooltipIcon } from "../../assets/icons";
import { useClickOutside } from "../../utils/functions";
import classNames from "classnames";

type TooltipProps = {
  requirementsText: string | string[];
  error?: boolean;
};

const Tooltip: FC<TooltipProps> = ({ requirementsText, error }) => {
  const [isWindowOpened, setIsWindowOpened] = useState(false);

  const menuRef = useRef(null);
  const btnRef = useRef(null);

  useClickOutside(menuRef, btnRef, () => setIsWindowOpened(false));

  const onOpenWindowBtnClick = () => {
    setIsWindowOpened((prevstate) => !prevstate);
  };

  return (
    <div className={styles.wrapper}>
      {isWindowOpened && (
        <div
          className={classNames(styles.menu, {
            [styles.errorMenu]: error,
          })}
          ref={menuRef}
        >
          {Array.isArray(requirementsText) ? (
            <ul className={styles.requirementsList}>
              {requirementsText.map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          ) : (
            <p>{requirementsText}</p>
          )}
        </div>
      )}
      <button
        className={classNames(styles.btn, {
          [styles.activeBtn]: isWindowOpened,
          [styles.errorBtn]: error,
        })}
        onClick={onOpenWindowBtnClick}
        ref={btnRef}
      >
        <TooltipIcon />
      </button>
    </div>
  );
};

export default Tooltip;
