import { FC } from "react";
import { BookingType, BookingStatus, BookingFormType } from "../../redux/types/bookingTypes";
import styles from "./CheckerboardBanner.module.scss";
import  classNames  from 'classnames';
import moment from "moment";
import { TooltipIcon } from "../../assets/icons";
import BannerInner from "./BannerInner";
import { useDispatch } from "react-redux";
import { setModalWindowData } from "../../redux/reducers/pageSlice";
import { ModalWindowTypes } from "../../utils/@globalTypes";

type BannerProps = {
  booking:BookingType,
  cellWidth:number,
  type?:  "start" | "ending",
  currentDay:moment.Moment
}

const CheckerboardBanner:FC<BannerProps> = ({
  booking,
  cellWidth,
  type = "start",
  currentDay
}) => {
  const dispatch = useDispatch();
  const handlerViewBooking=()=>{  
    dispatch(setModalWindowData({type: ModalWindowTypes.SHOW_RESERVATION, data:{booking:booking, type:BookingFormType.view}}))        
  }
  const today = new Date();
  
  const bookingClassname = moment(booking.check_out_date).startOf('day').unix()<moment(today).startOf('day').unix()? "last"
  : booking.status==="awt"? "await"
  : +booking.total===+booking.paid? "full_paid" 
  : +booking.paid>0? "part_paid"
  : "not_paid";

  
  const diff = type==="start"? moment(booking.check_out_date).diff(moment(booking.check_in_date),'days')
  : moment(booking.check_out_date).diff(currentDay,'days')

  const bannerWidth= type==="start"? (cellWidth*diff-2) : (cellWidth*diff)+ (cellWidth/2)-2
  const leftPosition= type==="start"? (cellWidth/2+1) : 1

  if(diff<4){
    return (
      <>
      <div className={classNames(styles.container, styles[bookingClassname], styles.tooltip)}
        style={{width:(cellWidth*diff-2), left:(cellWidth/2+1)}}
        onClick={handlerViewBooking}
      >
        <div className={styles.tt_name}>
          {booking.last_name} {booking.first_name}
        </div>
        <div className={styles.tt_icon}>
          <TooltipIcon />
        </div>
        <div className={styles.tt_full}>
          <BannerInner 
            booking={booking} 
            bookingClassname={bookingClassname} 
            bannerWidth={cellWidth*4} 
            left={0}
            isTooltip={true}/>
        </div>
      </div>
      </>
    )
  }
  return (    
    <BannerInner 
      booking={booking} 
      bookingClassname={bookingClassname} 
      bannerWidth={bannerWidth} 
      left={leftPosition}
      isTooltip={false}
    />
  )
}

export default CheckerboardBanner;