import React, { useState } from "react";
import styles from "./Header.module.scss";
import { CalendarIcon2, NotificationIcon, SearchIcon } from "../../../assets/icons";
import Input from "../../../components/Input";
import UserName from "../../../components/UserName";
import { useDispatch, useSelector } from "react-redux";
import { PageSelectors } from "../../../redux/reducers/pageSlice";
import { AuthSelectors, logoutUser } from "../../../redux/reducers/authSlice";
import { getUserType } from "../../../utils/functions";
import { InputType } from "../../../utils/@globalTypes";

const Header = () => {
  const dispatch = useDispatch();

  const pageData = useSelector(PageSelectors.getPageData);
  const userData = useSelector(AuthSelectors.getUserInfo);

  const [searchValue, setSearchValue] = useState("");

  const onSearchBtnClick = () => {};

  const onCalendarBtnClick = () => {};

  const onNotificationBtnClick = () => {};

  const DROPDOWN_BUTTONS_LIST = [
    {
      title: "Профиль",
      onClick: () => {},
    },
    {
      title: "Выйти",
      onClick: () => {
        dispatch(logoutUser());
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h1>{pageData?.pageTitle}</h1>
        <p>{pageData?.pageDescription}</p>
      </div>
      <div className={styles.rightControlsWrapper}>
        {/* <div className={styles.searchWrapper}>
          <Input
            value={searchValue}
            placeholder="Введите для поиска"
            className={styles.search}
            type={InputType.TEXT}
            onChange={setSearchValue}
            // onKeyDown={{ key: "Enter", func: onSearchBtnClick }}
          />
          <div className={styles.searchBtn} onClick={onSearchBtnClick}>
            <SearchIcon />
          </div>
        </div> */}
        <div className={styles.headerControls}>
          <div className={styles.headerBtn} onClick={onCalendarBtnClick}>
            <CalendarIcon2 />
          </div>
          <div className={styles.headerBtn} onClick={onNotificationBtnClick}>
            <NotificationIcon />
          </div>
        </div>
        <UserName
          username={!userData ? "Загрузка..." : `${userData.first_name} ${userData.last_name}`}
          jobTitle={!userData ? "" : getUserType(userData.user.status)}
          dropdownList={DROPDOWN_BUTTONS_LIST}
          // avatar="https://klike.net/uploads/posts/2019-03/1551511852_17.jpg"
        />
      </div>
    </div>
  );
};

export default Header;
