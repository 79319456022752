import React, { FC, ReactNode, useRef, useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";
import { ButtonType } from "../../utils/@globalTypes";
import { useClickOutside } from "../../utils/functions";
import { Link } from "react-router-dom";

type ButtonProps = {
  title: string | ReactNode;
  disabled?: boolean;
  type: ButtonType;
  className?: string;
  onClick?: () => void;
  confirmWindowText?: string;
  onKeyDown?: {
    key: string;
    func: () => void;
  };
  windowClassName?: string;
  wrapperClassName?: string;
  promptText?: string;
  isResponseText?: boolean;
  isStrokeIcon?: boolean;
  path?: string;
  linkState?: any;
};

const btnStyles = {
  [ButtonType.PRIMARY]: styles.primaryBtn,
  [ButtonType.SECONDARY]: styles.secondaryBtn,
  [ButtonType.PRIMARY_SMALL]: styles.primarySmallBtn,
  [ButtonType.SECONDARY_SMALL]: styles.secondarySmallBtn,
  [ButtonType.SMALL]: styles.smallBtn,
  [ButtonType.ERROR_SMALL]: styles.errorSmallBtn,
  [ButtonType.LINK]: styles.linkBtn,
};

const Button: FC<ButtonProps> = ({
  title,
  onClick,
  type,
  disabled,
  confirmWindowText,
  className,
  onKeyDown,
  windowClassName,
  wrapperClassName,
  promptText,
  isResponseText,
  isStrokeIcon,
  linkState,
  path,
}) => {
  const [isWindowOpened, setIsWindowOpened] = useState(false);
  const menuRef = useRef(null);
  const btnRef = useRef(null);

  const onKeyDownHandler = (event: globalThis.KeyboardEvent) => {
    if (onKeyDown && event.key === onKeyDown.key) {
      onKeyDown.func();
    }
  };

  useEffect(() => {
    if (onKeyDown && !disabled) {
      document.addEventListener("keydown", onKeyDownHandler);
      return () => {
        document.removeEventListener("keydown", onKeyDownHandler);
      };
    }
  }, [onKeyDown, disabled]);

  const btnClassName = btnStyles[type];

  useClickOutside(menuRef, btnRef, () => setIsWindowOpened(false));

  const onOpenWindowBtnClick = () => {
    setIsWindowOpened(true);
  };

  const onCloseWindowBtnClick = () => {
    setIsWindowOpened(false);
  };

  const onConfirmBtnClick = () => {
    onCloseWindowBtnClick();
    onClick && onClick();
  };
  return (
    <div
      className={classNames(
        styles.wrapper,
        wrapperClassName,
        wrapperClassName && styles[wrapperClassName]
      )}
    >
      {isWindowOpened && (
        <div
          className={classNames(styles.confirmWindow, windowClassName && styles[windowClassName])}
          ref={menuRef}
        >
          <p className={styles.confirmText}>{confirmWindowText}</p>
          <div className={styles.confirmControls}>
            <div onClick={onConfirmBtnClick}>Да</div>
            <div onClick={onCloseWindowBtnClick}>Нет</div>
          </div>
        </div>
      )}
      {path ? (
        <Link
          to={path}
          state={linkState}
          className={classNames(btnClassName, className, {
            [styles.fillIcon]: !isStrokeIcon,
            [styles.strokeIcon]: isStrokeIcon,
          })}
        >
          {title}
        </Link>
      ) : (
        <button
          ref={btnRef}
          onClick={disabled ? undefined : confirmWindowText ? onOpenWindowBtnClick : onClick}
          className={classNames(btnClassName, className, className && styles[className], {
            [styles.disabledBtn]: disabled,
            [styles.activeSmallBtn]: isWindowOpened,
            [styles.fillIcon]: !isStrokeIcon,
            [styles.strokeIcon]: isStrokeIcon,
          })}
        >
          {title}
        </button>
      )}
      {promptText && (
        <p
          className={classNames(styles.promptText, {
            [styles.responseText]: isResponseText,
          })}
        >
          {promptText}
        </p>
      )}
    </div>
  );
};

export default Button;
