import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageSelectors, setModalWindowData } from "../../../redux/reducers/pageSlice";
import { ButtonType, LoaderTypes, ModalWindowTypes } from "../../../utils/@globalTypes";
import Button from "../../../components/Button";
import styles from "./MainObjects.module.scss";
import MainObjectCard from "../../../components/MainObjectCard";
import EmptyState from "../../../components/EmptyState";
import { AddObjectIcon } from "../../../assets/icons";
import Loader from "../../../components/Loader";
import { MainObjectSelectors, removeMainObject } from "../../../redux/reducers/mainObjectSlice";
import { MainObject } from "../../../redux/types/mainObjectTypes";

const MainObjects = () => {
  const dispatch = useDispatch();

  const mainObjectList = useSelector(MainObjectSelectors.getMainObjectList);
  const isPageLoading = useSelector(PageSelectors.getLoadersData(LoaderTypes.MAIN_OBJECTS_PAGE));
  const countriesList = useSelector(MainObjectSelectors.getCountriesList);

  const onNewObjectBtnClick = () => {
    dispatch(setModalWindowData({ type: ModalWindowTypes.CREATE_MAIN_OBJECT }));
  };

  const onDeleteBtnClick = (id: string) => () => {
    dispatch(removeMainObject(id));
  };

  return !isPageLoading ? (
    mainObjectList && mainObjectList.length > 0 ? (
      <>
        <div className={styles.newObjectBtnWrappen}>
          <Button
            title="Новый объект"
            type={ButtonType.PRIMARY_SMALL}
            onClick={onNewObjectBtnClick}
          />
        </div>
        <div className={styles.cardListWrapper}>
          {mainObjectList.map((object) => {
            const { id, name, object_type, photo, full_address, country, isLoading } =
              object as MainObject & { isLoading: boolean };
            const findedCountry = countriesList.find((item) => item.countryCode === country);
            return (
              <div key={id}>
                <MainObjectCard
                  name={name}
                  id={id}
                  objectType={object_type.title}
                  address={full_address}
                  photo={photo}
                  country={findedCountry?.countryLabel}
                  isLoading={isLoading}
                  onDeleteBtnClick={onDeleteBtnClick(id)}
                />
              </div>
            );
          })}
        </div>
      </>
    ) : (
      <div className={styles.emptystateWrapper}>
        <EmptyState
          icon={<AddObjectIcon width="74" height="85" />}
          text="Объекты пока не добавлены"
          className={styles.emptystate}
        />
        <Button
          title="Новый объект"
          type={ButtonType.PRIMARY_SMALL}
          onClick={onNewObjectBtnClick}
        />
      </div>
    )
  ) : (
    <Loader />
  );
};

export default MainObjects;
