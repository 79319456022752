import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./Checkbox.module.scss";
import { CheckmarkIcon } from "../../assets/icons";

type CheckboxProps = {
  checked: boolean;
  onChange?: (value: boolean) => void;
  onClick?:any
  label: string | ReactNode;
  name: string;
  className?: string;
};

const Checkbox: FC<CheckboxProps> = ({ checked, label, name, className, onChange, onClick }) => {
  const handler = () => {
    if(onChange){
     onChange(!checked);
    }
    if(onClick){
      onClick(name)
    }
  };
  return (
    <div className={classNames(styles.checkbox, className&&styles[className])}>
      <input name={name} id={name} type="checkbox" checked={checked} onChange={handler} />
      <div className={styles.customCheckbox}>{checked && <CheckmarkIcon />}</div>
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

export default Checkbox;
