import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import { BookingFormType, BookingType } from "../../../redux/types/bookingTypes";
import { ButtonType, ModalWindowTypes } from "../../../utils/@globalTypes";
import { BookingSelectors, removeBooking } from "../../../redux/reducers/bookingSlice";
import { CalendarIcon2 } from "../../../assets/icons";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import Button from "../../Button";
import ColorBannerPaid from "../ColorBannerPaid";
import { getBookingIcon } from "../functions";

import styles from "./ListItem.module.scss";
import { getCountryFlag } from "../../../utils/getCountryFlag";



type ItemProps = {
  booking:BookingType,  
}

const ListItem = ({booking}:ItemProps) => {

  const {total, paid, check_out_date, check_in_date, status, id, first_name, last_name, nationality, rental_object}= booking;
  const dispatch = useDispatch();
  
  const onDeleteBtnClick = ()=>{    
    dispatch(removeBooking(id));
  };
  const icon = getBookingIcon(status);
  const objects = useSelector(BookingSelectors.getChessObjects); 
  const res = objects?.find(item=> item.rental_objects.find(obj=> obj.id===rental_object ))
  
  const object = res&&res.rental_objects.find(item=> item.id===rental_object)
  
  const isReadyToDelete = (moment(check_out_date).startOf('day').unix()<moment(new Date()).startOf('day').unix())|| status==="cld";
  
  const handlerViewBooking=()=>{  
    dispatch(setModalWindowData({type: ModalWindowTypes.SHOW_RESERVATION, data:{type:BookingFormType.view, booking:booking}}))        
  }
  const handlerEditBooking=((): void=>{  
   
    dispatch(setModalWindowData({type: ModalWindowTypes.SHOW_RESERVATION, data:{type:BookingFormType.edit, booking:booking}}))        
  })
  const flag = getCountryFlag(nationality)
  
  return(
    <div className={styles.container} >
      <div className={styles.left} onClick={handlerViewBooking}>
        <div className={styles.info}>

          <div className={styles.objectBlock}>
            <div className={styles.icon}>
              {icon}
            </div>
            <div className={styles.rent}>
              {object&&object.name}
            </div>
          </div>

          <div className={styles.personBlock}>
            <div className={styles.names}>
              <span className={styles.flag}>{flag({})}</span>
              {last_name} 
              {" "}
              {first_name}
            </div>        
            <div className={styles.dates}>
              <div className={styles.dates_left}>
                <CalendarIcon2/>
                <span className={styles.date}>{moment(check_in_date).format('DD.MM.YYYY')}</span>
              </div>
              <div className={styles.dates_right}>
                {" "}&mdash;{" "}
                <span className={styles.date}>{moment(check_out_date).format('DD.MM.YYYY')}</span>
              </div>
            </div>
          </div>

        </div>
        <div className={styles.banner}>
          <ColorBannerPaid 
            total={total}
            paid={paid}
            status={status}
            check_out_date={check_out_date}
          />
        </div>        
      </div>      
      <div className={styles.right}>          
        <Button
          type={ButtonType.SMALL}
          title={<EditIcon />}
          onClick={handlerEditBooking}
        />
        <Button
          type={ButtonType.SMALL}
          title={<DeleteIcon />}
          className="deleteBookingList"
          wrapperClassName="bookingList"
          windowClassName="bookingConfirm"
          onClick={onDeleteBtnClick}
          disabled={!isReadyToDelete}
          confirmWindowText="Вы действительно хотите удалить бронь?"
        />   
        {!isReadyToDelete&&<div className={styles.help}>
            Для удаления брони необходимо изменить ее статус на "Отменена"
        </div>}     
      </div>
    </div>
  )

}

export default ListItem;