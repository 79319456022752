import { ErrorResponseData } from '../../redux/types/@types';
import { OptionsListType } from '../../utils/@globalTypes';
import { BookingFormType, MainObjectShort, BookingStatus } from './../../redux/types/bookingTypes';

export const getTitle = (type:BookingFormType) =>{
  switch (type){
    case BookingFormType.view:
      return "Бронь";
    case BookingFormType.edit:
      return "Бронь";
    case BookingFormType.new:
      return "Новая бронь";
  }
}

export const createObjectList = (objects:MainObjectShort[])=>{
  const rentalObjectsList:OptionsListType=[];
  if (objects.length>0){
    objects.map(item=>{ 
      if(item.rental_objects&&item.rental_objects.length>0){
      item.rental_objects.map(obj=>{
        
        const rental={
          value:obj.id.toString(),
          label:obj.name
        }
        return rentalObjectsList.push(rental)
        }
      )
      }
    })
    return rentalObjectsList
  }
}

export const statusListOption:OptionsListType = [
  {
    value:"new",
    label: "Новая"
  },
  {
    value:"que",
    label: "В очереди"
  },
  {
    value:"awt",
    label: "Ожидает подтверждения"
  },
  {
    value:"cfm",
    label: "Подтверждена"
  },
  {
    value:"cld",
    label: "Отменена"
  },
]
export const agesListOption:OptionsListType = [
  {
    value:"0",
    label: "до 1 года"
  },
  {
    value:"1",
    label: "1 год"
  },
  {
    value:"2",
    label: "2 года"
  },
  {
    value:"3",
    label: "3 года"
  },
  {
    value:"4",
    label: "4 года"
  },
  {
    value:"5",
    label: "5 лет"
  },
  {
    value:"6",
    label: "6 лет"
  },
  {
    value:"7",
    label: "7 лет"
  },
  {
    value:"8",
    label: "8 лет"
  },
  {
    value:"9",
    label: "9 лет"
  },
  {
    value:"10",
    label: "10 лет"
  },
  {
    value:"11",
    label: "11 лет"
  },
  {
    value:"12",
    label: "12 лет"
  },
  {
    value:"13",
    label: "13 лет"
  },
  {
    value:"14",
    label: "14 лет"
  },
  {
    value:"15",
    label: "15 лет"
  },
  {
    value:"16",
    label: "16 лет"
  },
  {
    value:"17",
    label: "17 лет"
  },
]

export const timeListOption:OptionsListType = [
  {
    value:"14:00",
    label: "14:00"
  },
  {
    value:"15:00",
    label: "15:00"
  },
  {
    value:"16:00",
    label: "16:00"
  },
  {
    value:"17:00",
    label: "17:00"
  },
  {
    value:"18:00",
    label: "18:00"
  },
  {
    value:"19:00",
    label: "19:00"
  },
  {
    value:"20:00",
    label: "20:00"
  },
  {
    value:"21:00",
    label: "21:00"
  },
  {
    value:"22:00",
    label: "22:00"
  },
  {
    value:"23:00",
    label: "23:00"
  }  
]

export const formatDate = (data:Date) => {
  const year = data.getFullYear();
  const month = data.getMonth();
  const curMonth = month < 9 ? `0${month+1}`: `${month+1}`
  const date = data.getDate();
  const curDate = date < 10 ? `0${date}`: date
  return `${year}-${curMonth}-${curDate}`
}

export const getErrorBookingText = (error:ErrorResponseData)=> {
  switch(error){
    // case 'rental_object: ["Rental object is not available on these dates"]':
    // return "Объект занят в указанные даты"

    default:
      return "Ошибка сохранения"
  }
}