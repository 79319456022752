import React, { FC } from "react";
import styles from "./BookingSettings.module.scss";

type BookingSettingsProps = {};

const BookingSettings: FC<BookingSettingsProps> = ({}) => {
  return <div></div>;
};

export default BookingSettings;
