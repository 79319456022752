import React from "react";
import styles from "./CreatingReservationWindow.module.scss";
import ModalWindow from "../ModalWindow";
import NewBookingContainer from "../../Booking/NewBookingContainer";

const CreatingReservationWindow = () => {
  
  return (
    <ModalWindow isLoading={false} isFullCustom hideCross windowClassname={styles.window}>
      <NewBookingContainer/>
    </ModalWindow>
  );
};

export default CreatingReservationWindow;
