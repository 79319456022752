import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonType,
  InputModeType,
  InputType,
  FieldTypes,
  OptionsListType,
} from "../../../utils/@globalTypes";
import Input from "../../../components/Input";
import SelectComponent from "../../../components/SelectComponent";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import { banDoubleSpace, getErrorText, setFieldErrorText } from "../../../utils/functions";
import {
  OBJECT_NAME_REQ,
  EntityTaxNumberList,
  ENT_TAXPAYER_NUMBER_REQ,
  ADDRESS_REQ,
  REGION_REQ,
  LOCALITY_REQ,
  ENTITY_NAME_ERROR,
  TAXPAYER_NUMBER_ERROR,
} from "../../../utils/constants";

import styles from "./EntitySettings.module.scss";
import { RoutesList } from "../../../App";
import { UpdateEntityData } from "../../../redux/types/userTypes";
import { UserSelectors, updateEntityInfo } from "../../../redux/reducers/userSlice";
import { MainObjectSelectors } from "../../../redux/reducers/mainObjectSlice";
import classNames from "classnames";
import { AuthSelectors, setErrorResponeData } from "../../../redux/reducers/authSlice";

type EntitySettingsType = {
  id?: number;
};
const EntitySettings: FC<EntitySettingsType> = ({ id }) => {
  const dispatch = useDispatch();
  const entity = useSelector(UserSelectors.getEntityInfo);
  const countriesList: OptionsListType = useSelector(MainObjectSelectors.getCountriesOptions);
  const errorResponseData = useSelector(AuthSelectors.getErrorResponseData);

  const [entityName, setEntityName] = useState("");
  const [country, setCountry] = useState("");
  const [taxpayerNumber, setTaxpayerNumber] = useState("");
  const [taxpayerPlaceholder, setTaxpayerPlaceholder] = useState("Введите УНП");

  const [adressArea, setAdressarea] = useState("");
  const [adressTown, setAdresstown] = useState("");
  const [adressStreet, setAdresstreet] = useState("");

  const [sameAdress, setSameAdress] = useState(false);
  const [initialCheckbox, setInitialCheckbox] = useState(false);

  const [postAdressArea, setPostAdressarea] = useState("");
  const [postAdressTown, setPostAdresstown] = useState("");
  const [postAdressStreet, setPostAdresstreet] = useState("");

  const [entityNameError, setEntityNameError] = useState("");
  const [taxpayernumberError, setTaxpayernumberError] = useState("");

  const [adressAreaError, setAdressareaError] = useState("");
  const [adressTownError, setAdresstownError] = useState("");
  const [adresStreetError, setAdresstreetError] = useState("");

  const [postAdressAreaError, setPostAdressareaError] = useState("");
  const [postAdressTownError, setPostAdresstownError] = useState("");
  const [postAdresStreetError, setPostAdresstreetError] = useState("");
  const [postAddressError, setPostAddressError] = useState(false);

  const [entityNameTouched, setEntityNameTouched] = useState(false);
  const [taxpayerNumberTouched, setTaxpayerNumberTouched] = useState(false);

  const [adressareaTouched, setAdressareaTouched] = useState(false);
  const [adresstownTouched, setAdresstownTouched] = useState(false);
  const [adresstreetTouched, setAdresstreetTouched] = useState(false);

  const [postAdressareaTouched, setPostAdressareaTouched] = useState(false);
  const [postAdresstownTouched, setPostAdresstownTouched] = useState(false);
  const [postAdresstreetTouched, setPostAdresstreetTouched] = useState(false);

  const onConfirmBtnClick = () => {
    setEntityNameTouched(true);
    setTaxpayerNumberTouched(true);
    setAdressareaTouched(true);
    setAdresstownTouched(true);
    setAdresstreetTouched(true);
    setPostAdressareaTouched(true);
    setPostAdresstownTouched(true);
    setPostAdresstreetTouched(true);
    const postPropertyArr = [postAdressArea, postAdressTown, postAdressStreet];
    const isPostPropertyError = !(
      postPropertyArr.every((item) => item.length > 0) ||
      postPropertyArr.every((item) => item.length === 0)
    );
    setPostAddressError(isPostPropertyError);

    if (
      !!entity &&
      !entityNameError &&
      !adressAreaError &&
      !adressTownError &&
      !adresStreetError &&
      !taxpayernumberError &&
      !postAdressAreaError &&
      !postAdressTownError &&
      !postAdresStreetError &&
      entityName.length > 0 &&
      adressArea.length > 0 &&
      adressTown.length > 0 &&
      adressStreet.length > 0 &&
      taxpayerNumber.length > 0 &&
      !isPostPropertyError
    ) {
      const { entity_address, post_address, country: countryRes, name, unp } = entity;

      const adressData = {
        region: adressArea,
        locality: adressTown,
        address: adressStreet,
      };

      const postAddressData = {
        region: postAdressArea,
        locality: postAdressTown,
        address: postAdressStreet,
      };

      const datas: UpdateEntityData = {};

      name !== entityName && (datas.name = entityName);
      unp !== taxpayerNumber && (datas.unp = taxpayerNumber);
      countryRes !== country && (datas.country = country);

      if (
        entity_address.region !== adressArea ||
        entity_address.locality !== adressTown ||
        entity_address.address !== adressStreet
      ) {
        datas.entity_address = adressData;
      }

      if (!sameAdress) {
        if (
          post_address.region !== postAdressArea ||
          post_address.locality !== postAdressTown ||
          post_address.address !== postAdressStreet
        ) {
          datas.post_address = postPropertyArr.every((item) => item.length > 0)
            ? postAddressData
            : null;
        }
      } else {
        datas.post_address = adressData;
      }

      id && dispatch(updateEntityInfo({ id, data: datas }));
    }
  };

  useEffect(() => {
    postAddressError && setPostAddressError(false);
  }, [postAdressArea, postAdressTown, postAdressStreet]);

  const setSameAdressHandler = (value: boolean) => {
    if (entity) {
      const {
        post_address: { region, locality, address },
      } = entity;
      region !== null && setPostAdressarea(region);
      locality !== null && setPostAdresstown(locality);
      address !== null && setPostAdresstreet(address);
    }

    setSameAdress(value);
  };

  useLayoutEffect(() => {
    if (entity) {
      const { entity_address, post_address, country: countryRes, name, unp } = entity;

      name !== null && setEntityName(name);
      countryRes !== null && setCountry(countryRes);
      unp !== null && setTaxpayerNumber(unp);
      entity_address.region !== null && setAdressarea(entity_address.region);
      entity_address.locality !== null && setAdresstown(entity_address.locality);
      entity_address.address !== null && setAdresstreet(entity_address.address);

      if (
        JSON.stringify(entity_address) === JSON.stringify(post_address) &&
        entity_address.address !== null
      ) {
        setInitialCheckbox(true);
        setSameAdress(true);
      } else {
        post_address.region !== null && setPostAdressarea(post_address.region);
        post_address.locality !== null && setPostAdresstown(post_address.locality);
        post_address.address !== null && setPostAdresstreet(post_address.address);
      }
    }
  }, [entity]);

  useEffect(() => {
    const choice = EntityTaxNumberList.find((item) => item.value === country);
    setTaxpayerPlaceholder(choice?.label || "Введите УНП");
  }, [country]);

  // useEffect(() => {
  //   sameAdress && setPostAdressarea(adressArea);
  // }, [sameAdress, adressArea]);

  // useEffect(() => {
  //   sameAdress && setPostAdresstown(adressTown);
  // }, [sameAdress, adressTown]);

  // useEffect(() => {
  //   sameAdress && setPostAdresstreet(adressStreet);
  // }, [sameAdress, adressStreet]);

  useEffect(() => {
    if (errorResponseData) {
      errorResponseData.name &&
        setEntityNameError(
          getErrorText(errorResponseData.name[0], ENTITY_NAME_ERROR, FieldTypes.ENTITY_NAME)
        );

      errorResponseData.unp &&
        setTaxpayernumberError(
          getErrorText(
            errorResponseData.unp[0],
            TAXPAYER_NUMBER_ERROR,
            FieldTypes.ENT_TAXPAYER_NUMBER
          )
        );

      // errorResponseData.region &&
      //   setAdressareaError(
      //     getErrorText(errorResponseData.region[0], REGION_ERROR, FieldTypes.REGION)
      //   );

      // errorResponseData.locality &&
      //   setAdresstownError(
      //     getErrorText(errorResponseData.locality[0], LOCALITY_ERROR, FieldTypes.LOCALITY)
      //   );

      // errorResponseData.address &&
      //   setAdresstreetError(
      //     getErrorText(errorResponseData.address[0], ADDRESS_ERROR, FieldTypes.ADDRESS)
      //   );

      // Разделить названия полей адресов на беке

      dispatch(setErrorResponeData(null));
    }
  }, [errorResponseData]);

  // EntityName

  useEffect(() => {
    setFieldErrorText(
      entityNameTouched,
      entityName,
      setEntityNameError,
      FieldTypes.ENTITY_NAME,
      true
    );
  }, [entityNameTouched, entityName]);

  // TaxpayerNumber

  useEffect(() => {
    setFieldErrorText(
      taxpayerNumberTouched,
      taxpayerNumber,
      setTaxpayernumberError,
      FieldTypes.ENT_TAXPAYER_NUMBER,
      true
    );
  }, [taxpayerNumberTouched, taxpayerNumber]);

  // Address

  useEffect(() => {
    setFieldErrorText(adressareaTouched, adressArea, setAdressareaError, FieldTypes.REGION, true);
  }, [adressareaTouched, adressArea]);

  useEffect(() => {
    setFieldErrorText(adresstownTouched, adressTown, setAdresstownError, FieldTypes.LOCALITY, true);
  }, [adresstownTouched, adressTown]);

  useEffect(() => {
    setFieldErrorText(
      adresstreetTouched,
      adressStreet,
      setAdresstreetError,
      FieldTypes.ADDRESS,
      true
    );
  }, [adresstreetTouched, adressStreet]);

  // Post Address

  useEffect(() => {
    setFieldErrorText(
      postAdressareaTouched,
      postAdressArea,
      setPostAdressareaError,
      FieldTypes.REGION,
      false
    );
  }, [postAdressareaTouched, postAdressArea]);

  useEffect(() => {
    setFieldErrorText(
      postAdresstownTouched,
      postAdressTown,
      setPostAdresstownError,
      FieldTypes.LOCALITY,
      false
    );
  }, [postAdresstownTouched, postAdressTown]);

  useEffect(() => {
    setFieldErrorText(
      postAdresstreetTouched,
      postAdressStreet,
      setPostAdresstreetError,
      FieldTypes.ADDRESS,
      false
    );
  }, [postAdresstreetTouched, postAdressStreet]);

  const isValid = useMemo(() => {
    return (
      !entityNameError &&
      !taxpayernumberError &&
      !adressAreaError &&
      !adressTownError &&
      !adresStreetError &&
      !postAdressAreaError &&
      !postAdressTownError &&
      !postAdresStreetError &&
      !postAddressError
    );
  }, [
    entityNameError,
    postAdressAreaError,
    postAdressTownError,
    postAdresStreetError,
    taxpayernumberError,
    adressAreaError,
    adressTownError,
    adresStreetError,
    postAddressError,
  ]);

  const isFieldsChanged = useMemo(() => {
    if (!!entity) {
      const { entity_address, post_address, country: countryRes, name, unp } = entity;

      return (
        name !== entityName ||
        countryRes !== country ||
        unp !== taxpayerNumber ||
        entity_address.region !== adressArea ||
        entity_address.locality !== adressTown ||
        entity_address.address !== adressStreet ||
        (!sameAdress &&
          (post_address.region !== postAdressArea ||
            post_address.locality !== postAdressTown ||
            post_address.address !== postAdressStreet)) ||
        (sameAdress !== initialCheckbox && !initialCheckbox)
      );
    }
  }, [
    entity,
    entityName,
    country,
    taxpayerNumber,
    adressArea,
    adressTown,
    adressStreet,
    postAdressArea,
    postAdressTown,
    postAdressStreet,
    sameAdress,
    initialCheckbox,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.column}>
          <h3 className={styles.title}>Данные юридического лица</h3>
          <Input
            id="entityName"
            value={entityName}
            title="Наименование юридического лица*"
            name="entityName"
            placeholder="Введите наименование"
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}
            errText={entityNameError}
            onChange={setEntityName}
            onBlur={setEntityNameTouched}
            requirementsText={OBJECT_NAME_REQ}
            customBanSymbols={banDoubleSpace}
            autoComplete="organization"
          />
          <SelectComponent
            title="Страна*"
            optionsList={countriesList}
            currentValue={country}
            isSearchable={true}
            setSelecValue={setCountry}
            defaultValueId={0}
          />
          <Input
            id="taxpayerNumber"
            value={taxpayerNumber}
            title="Номер налогоплательщика*"
            name="taxpayerNumber"
            placeholder={taxpayerPlaceholder}
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}
            errText={taxpayernumberError}
            onChange={setTaxpayerNumber}
            onBlur={setTaxpayerNumberTouched}
            requirementsText={ENT_TAXPAYER_NUMBER_REQ}
            banSymbols={/\s/g}
          />
        </div>
        <div className={styles.column}>
          <h3 className={styles.title}>Юридический адрес</h3>
          <Input
            id="adress_area"
            value={adressArea}
            title="Административное деление*"
            name="adress_area"
            placeholder="Введите административное деление"
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}
            errText={adressAreaError}
            onChange={setAdressarea}
            onBlur={setAdressareaTouched}
            requirementsText={REGION_REQ}
            customBanSymbols={banDoubleSpace}
            autoComplete="address-level1"
          />
          <Input
            id="adress_town"
            value={adressTown}
            title="Населенный пункт*"
            name="adress_town"
            placeholder="Введите населенный пункт"
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}
            errText={adressTownError}
            onChange={setAdresstown}
            onBlur={setAdresstownTouched}
            requirementsText={LOCALITY_REQ}
            customBanSymbols={banDoubleSpace}
            autoComplete="address-level2"
          />
          <Input
            id="adress_street"
            value={adressStreet}
            title="Адрес*"
            name="adress_street"
            placeholder="Введите адрес"
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}
            errText={adresStreetError}
            onChange={setAdresstreet}
            onBlur={setAdresstreetTouched}
            requirementsText={ADDRESS_REQ}
            customBanSymbols={banDoubleSpace}
            autoComplete="street-address"
          />
          <Checkbox
            label="Юридический и почтовый адрес совпадают"
            checked={sameAdress}
            onChange={setSameAdressHandler}
            name="same_adress"
            className={styles.checkBox}
          />
          {!sameAdress && (
            <div
              className={classNames(styles.column, {
                [styles.postInputsError]: postAddressError,
              })}
            >
              <div>
                <h3 className={styles.title}>
                  Почтовый адрес <span>(необязательно)</span>
                </h3>
                {postAddressError && (
                  <p className={styles.postAddressError}>
                    Заполните все поля почтового адреса или оставьте их пустыми
                  </p>
                )}
              </div>
              <Input
                id="post_adress_area"
                value={postAdressArea}
                title="Административное деление"
                name="post_adress_area"
                placeholder="Введите административное деление"
                errText={postAdressAreaError}
                type={InputType.TEXT}
                inputMode={InputModeType.TEXT}
                onBlur={setPostAdressareaTouched}
                onChange={setPostAdressarea}
                disabled={sameAdress}
                requirementsText={REGION_REQ}
                customBanSymbols={banDoubleSpace}
                autoComplete="address-level1"
              />
              <Input
                id="post_adress_town"
                value={postAdressTown}
                title="Населенный пункт"
                name="post_adress_town"
                placeholder="Введите населенный пункт"
                errText={postAdressTownError}
                type={InputType.TEXT}
                inputMode={InputModeType.TEXT}
                onBlur={setPostAdresstownTouched}
                onChange={setPostAdresstown}
                disabled={sameAdress}
                requirementsText={ADDRESS_REQ}
                customBanSymbols={banDoubleSpace}
                autoComplete="address-level2"
              />
              <Input
                id="post_adress_street"
                value={postAdressStreet}
                title="Адрес"
                name="adress_street"
                placeholder="Введите адрес"
                errText={postAdresStreetError}
                type={InputType.TEXT}
                inputMode={InputModeType.TEXT}
                onBlur={setPostAdresstreetTouched}
                onChange={setPostAdresstreet}
                disabled={sameAdress}
                requirementsText={ADDRESS_REQ}
                customBanSymbols={banDoubleSpace}
                autoComplete="street-address"
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.btnFormWrap}>
        <Button
          title="Сохранить"
          type={ButtonType.PRIMARY_SMALL}
          onClick={onConfirmBtnClick}
          disabled={!isValid || !isFieldsChanged}
          className={styles.btnConfirm}
        />
        <Button
          title="Отмена"
          type={ButtonType.SECONDARY_SMALL}
          path={RoutesList.checkerboard.fullPath}
          className={styles.btnCancel}
        />
      </div>
    </div>
  );
};

export default EntitySettings;
