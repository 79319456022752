import { useEffect, useLayoutEffect, useState } from "react";
import { BookingFormType, BookingType, Gender, UpdateBookingType } from "../../../redux/types/bookingTypes";
import { FieldTypes, InputModeType, InputType, OptionsListType, OptionType } from "../../../utils/@globalTypes";
import { 
  FIRST_NAME_REQ, 
  LAST_NAME_REQ, 
  MIDDLE_NAME_REQ, 
  } from "../../../utils/constants";
import Input from "../../Input";
import BookingSectionTitle from "../BookingSectionTitle";
import BookingLineTitle from "../BookingLineTitle";
import RadioButton from "../../RadioButton";
import { setFieldErrorText } from "../../../utils/functions";
import SelectComponent from "../../SelectComponent";
import { useDispatch, useSelector } from "react-redux";
import { BookingFormSelectors, completeSave, hasErrors, setPersonBlockValid, startEdit } from "../../../redux/reducers/bookingFormSlice";
import { MainObjectSelectors } from "../../../redux/reducers/mainObjectSlice";
import Calendar from "../../Calendar";

import styles from "./BookingPersonBlock.module.scss";
import { formatDate } from "../functions";

type PersonblockProps ={
  windowType:BookingFormType,
  booking?:BookingType   
}

const BookingPersonBlock = ({
  windowType=BookingFormType.view, 
  booking  
}:PersonblockProps) => {

  const dispatch = useDispatch();
  const {
    isActiveSaveButton,
    isStartSave,
    isHasErrors,
    objectBlockValid,
    personBlockValid
  } = useSelector(BookingFormSelectors.getBookingForm);

  const countriesList:OptionsListType = useSelector(MainObjectSelectors.getCountriesOptions)
    useEffect(() => {
     countriesList[15]={ value: "ot", label: "Другое" };  
    }, []);  

  const [firstName, setFirstName]= useState("");
  const [surName, setSurname]= useState("");
  const [middleName, setMiddlename]= useState("");
  const [country, setCountry]= useState("");
  const [birthDate, setBirthdate]= useState<Date>();

  const [firstNameError, setFirstNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [middlenameError, setMiddlenameError] = useState("");
  const [countryError, setCountryError] = useState("");

  const [nameTouched, setNameTouched] = useState(false);
  const [surnameTouched, setSurnameTouched] = useState(false);
  const [middlenameTouched, setMiddlenameTouched] = useState(false);
  const [countryTouched, setCountryTouched] = useState(false);
  const [birthdayTouched, setBirthdayTouched] = useState(false);

  const [isNameFocus, setNameFocus] = useState(false);
  const [isSurnameFocus, setSurnameFocus] = useState(false);
  const [isMiddlenameFocus, setMiddlenameFocus] = useState(false);
  const [isCountryFocus, setCountryFocus] = useState(false);
  
  const RadioButtonList = ["Мужской", "Женский"];  
  const [activeRadioBtn, setActiveRadioBtn] = useState<string>("");
  const getSex = (activeRadioBtn:string) => {
    if(activeRadioBtn==="Мужской"){

       return "mal"
    } else if(activeRadioBtn==="Женский"){
      return "fem"
    } else return ""  
  }
  
   useLayoutEffect(() => {  
    if(booking){
      const {
        first_name,
        last_name,
        second_name,
        nationality,
        sex,
        birth_day                    
      } = booking;   
      first_name!==null&&setFirstName(first_name);
      last_name!==null&&setSurname(last_name);
      second_name&& setMiddlename(second_name);
      nationality!==null&&setCountry(nationality);
      sex!==null&&booking.sex==="mal"?setActiveRadioBtn(RadioButtonList[0])
      :sex==="fem"?setActiveRadioBtn(RadioButtonList[1])
      :setActiveRadioBtn("");
      birth_day && setBirthdate(new Date(birth_day))
    }
  }, [booking]); 

  // Name
  
   useEffect(() => {
    setFieldErrorText( nameTouched, firstName, setFirstNameError, FieldTypes.FIRST_NAME, true, undefined, setNameFocus);
  }, [firstName, nameTouched]);  

    // Surname

  useEffect(() => {
    setFieldErrorText(surnameTouched, surName, setSurnameError, FieldTypes.LAST_NAME, true, undefined, setSurnameFocus);
  }, [surName, surnameTouched]);

  // Middlename

  useEffect(() => {
    setFieldErrorText(middlenameTouched, middleName, setMiddlenameError, FieldTypes.MIDDLE_NAME, false, undefined, setMiddlenameFocus);
  }, [middleName, middlenameTouched]);

  useEffect(() => {
    setFieldErrorText(countryTouched, country, setCountryError, FieldTypes.COUNTRY, true);
  }, [country, countryTouched]);

  useEffect(()=> {     //проверяем на изменение данные
    if((nameTouched||surnameTouched||middlenameTouched||countryTouched||birthdayTouched)&& 
      (booking?.first_name!==firstName || 
        booking.last_name!==surName || 
        booking.second_name!== middleName|| 
        booking.nationality !==country || 
        booking.birth_day!== birthDate ||
        booking.sex !== getSex(activeRadioBtn))&&
      !isActiveSaveButton){  
        console.log("startedit")        
        dispatch(startEdit())
      }    
  }, [nameTouched, surnameTouched, middlenameTouched,countryTouched, birthdayTouched,activeRadioBtn]);  

  useEffect(()=> {
    if(isStartSave && windowType !== BookingFormType.view){
      tryValidBlock();      
    }
  }, [isStartSave]);

  const tryValidBlock=()=>{
    setNameTouched(true);
    setSurnameTouched(true);
    setMiddlenameTouched(true);
    setCountryTouched(true);
    setNameFocus(false);
    setSurnameFocus(false);
    setMiddlenameFocus(false);
    setCountryFocus(false)
    if(
      !firstNameError&&
      !surnameError&&
      !middlenameError&&
      !countryError&&
      firstName.length > 0 &&
      surName.length > 0&&
      country      
    ){
      const newPersonData:UpdateBookingType ={};
      if(windowType === BookingFormType.edit&&booking){        
        booking.first_name!==firstName&& (newPersonData.first_name = firstName);
        booking.last_name!==surName && (newPersonData.last_name = surName);
        booking.second_name!==middleName && (newPersonData.second_name = middleName);
        booking.nationality!== country && (newPersonData.nationality = country);
        booking.sex!== getSex(activeRadioBtn) && (newPersonData.sex = getSex(activeRadioBtn));
        booking.birth_day !== birthDate && birthDate && (newPersonData.birth_day = formatDate(birthDate));          
      }
      if(windowType === BookingFormType.new){        
        newPersonData.first_name = firstName;
        newPersonData.last_name = surName;
        newPersonData.second_name = middleName;
        newPersonData.nationality = country;
        newPersonData.sex = getSex(activeRadioBtn);
        newPersonData.birth_day = birthDate && formatDate(birthDate);        
      }
      dispatch(setPersonBlockValid({isValid:true, update:newPersonData}))
    } else{
      dispatch(setPersonBlockValid({isValid:false, update:{}}))
      dispatch(hasErrors())
    }
  }    
  console.log(isHasErrors, objectBlockValid?.isValid , personBlockValid?.isValid,isStartSave)
  useEffect(() => {
    if(isHasErrors && objectBlockValid?.isValid && !personBlockValid?.isValid&&isStartSave){
      scrollToError();
    }
  }, [isHasErrors,objectBlockValid, personBlockValid, isStartSave]);
    
    const scrollToError = ()=>{
      console.log("старт скролла")
      if(surnameError) {
          setSurnameFocus(true);
        return ;
      } else if(firstNameError){      
          setNameFocus(true)
        return
      } else if(middlenameError){       
          setMiddlenameFocus(true)
        return
      } else if(countryError){
          setCountryFocus(true)
        return
      }
      
    }
  
  return (
    <div className={styles.wrap}>
      <BookingSectionTitle title="Личная информация"/>
      <div className={styles.container}>
        <div className={styles.leftColumn}>
        <Input
            id="surName"
            value={surName}
            title={windowType===BookingFormType.view ? "Фамилия" : "Фамилия*"}
            disabled={windowType===BookingFormType.view ? true : false}
            name= "surName"
            placeholder= "Введите фамилию"
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}            
            errText={windowType===BookingFormType.view ? undefined: surnameError }
            className= {styles.booking}
            containerClassName={styles.inputСontainer}
            onChange={setSurname}
            requirementsText={windowType===BookingFormType.view ? undefined: LAST_NAME_REQ}
            isBooking={true}
            onBlur={setSurnameTouched}
            banSymbols={/\s/g}
            isFocus={isSurnameFocus}
          />
        <Input
            id="firstName"
            value={firstName}
            title={windowType===BookingFormType.view ? "Имя" : "Имя*"}
            disabled={windowType===BookingFormType.view ? true: false }
            name= "firstName"
            placeholder= "Введите имя"
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}            
            errText={windowType===BookingFormType.view ?undefined: firstNameError }
            className= {styles.booking}
            containerClassName={styles.inputСontainer}
            onChange={setFirstName}
            onBlur={setNameTouched}
            requirementsText={windowType===BookingFormType.view ? undefined: FIRST_NAME_REQ }
            isFocus={isNameFocus}
            isBooking={true}
            banSymbols={/\s/g}
          />
          <Input
            id="middleName"
            value={middleName}
            title="Отчество"
            disabled={windowType===BookingFormType.view ? true: false }
            name="middleName"
            placeholder= {windowType===BookingFormType.view ?"-": "Введите отчество" }
            type={InputType.TEXT}
            inputMode={InputModeType.TEXT}            
            errText={windowType===BookingFormType.view ? undefined: middlenameError }
            containerClassName={styles.inputСontainer}
            className= {styles.booking}
            onChange={setMiddlename}
            onBlur={setMiddlenameTouched}
            isBooking={true}
            isFocus={isMiddlenameFocus}
            requirementsText={windowType===BookingFormType.view ?undefined: MIDDLE_NAME_REQ }
          />
        </div>
        <div className={styles.rightColumn}>
          <SelectComponent
            title={windowType===BookingFormType.view ? "Гражданство" : "Гражданство*"}
            optionsList={countriesList}
            currentValue={country}
            placeholder="Укажите гражданство"
            isSearchable={windowType===BookingFormType.view ? true: false }
            isDisabled={windowType===BookingFormType.view ? true: false }
            setSelecValue={setCountry}
            errText={windowType===BookingFormType.view ? undefined: countryError }
            isBooking={true}
            onBlur={setCountryTouched}
            isFocus={isCountryFocus}
          />
          <div className={styles.line}>
            <BookingLineTitle title="Дата рождения"/>
            <Calendar
              disabled={windowType===BookingFormType.view ? true: false }
              placeholderText={windowType===BookingFormType.view ? "-": "Введите дату рождения" }
              selectedDate={birthDate}
              onChange={setBirthdate}
              showYearDropdown={true}
              showMonthDropdown={true}
              isSmall={true}    
              maxDate={new Date()}    
              excludeDates={[new Date()]}   
              onBlur={setBirthdayTouched}   
            />
          </div>
          <div className={styles.line}>
            <BookingLineTitle title="Пол"/>
            <RadioButton
              btnsList={RadioButtonList}
              onClick={setActiveRadioBtn}
              activeTab={activeRadioBtn}
              size="booking"
              isDisabled={windowType===BookingFormType.view ? true: false }
            />
          </div>
        </div>
      </div>
    </div>
    )
}

export default BookingPersonBlock ;