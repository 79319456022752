import React from "react";

export const LogoIcon = ({ width = "347", height = "69", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 347 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2130_3381)">
        <path
          d="M33.7195 65.2257H29.2044L52.7194 14.4723H62.9884L36.6317 69.3185H25.9281L-0.422729 14.4723H9.91094L33.7195 65.2257Z"
          fill={fill}
        />
        <path
          d="M121.244 18.9367H130.856V69.3185H121.244V18.9367ZM98.6748 14.4723H153.426V22.9587H98.6748V14.4723Z"
          fill={fill}
        />
        <path
          d="M157.483 48.7718H195.635V56.7451H157.483V48.7718ZM182.824 14.4723L209.034 69.3185H198.765L174.956 18.1287H178.673L154.864 69.3185H144.601L170.811 14.4723H182.824Z"
          fill="white"
        />
        <path d="M76.9683 69.3244H86.5915V37.9913H76.9683V69.3244Z" fill={fill} />
        <path
          d="M93.5491 33.4856H69.0477L71.7544 27.8241L77.0328 16.7782H85.564L90.8424 27.8241L93.5491 33.4856Z"
          fill="#F39130"
        />
        <path
          d="M98.5456 61.1505H64.0511L67.8558 53.1772L75.289 37.6315H87.3019L94.7351 53.1772L98.5456 61.1505Z"
          fill={fill}
        />
        <path
          d="M259.986 45.9174H221.833V37.8733H259.986V45.9174ZM228.169 41.8954L224.311 66.3933L219.726 60.9854H262.1V69.3185H214.482L218.851 41.8954L214.482 14.4723H261.73V22.8113H219.726L224.311 17.3974L228.169 41.8954Z"
          fill="white"
        />
        <path
          d="M346.577 14.4723V69.3185H337.4V17.3974L340.389 17.5449L314.977 69.3185H304.714L279.303 17.6216L282.291 17.4741V69.3185H273.114V14.4723H287.822L312.282 65.149H307.48L331.869 14.4723H346.577Z"
          fill="white"
        />
        <path d="M86.8616 12.5321H75.7294L81.4951 0.401031L86.8616 12.5321Z" fill="#69C931" />
      </g>
      <defs>
        <clipPath id="clip0_2130_3381">
          <rect width="347" height="69" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
