import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import "intl-tel-input/build/css/intlTelInput.css";
import "./IntlTelInput.scss";
import intlTelInput from "intl-tel-input";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { MainObjectSelectors } from "../../redux/reducers/mainObjectSlice";
import { TelephoneType } from "../../utils/@globalTypes";

type InputProps = {
  id: string;
  value: TelephoneType;
  title?: string;
  placeholder: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errText?: string;
  className?: string;
  // setCountryCode: (value: string) => void;
  setIsValidate: (value: boolean) => void;
  setError: (value: string) => void;
  onChange: React.Dispatch<React.SetStateAction<TelephoneType>>;
  onBlur?: (value: boolean) => void;
  preloadedData?: TelephoneType | null;
  isFocus?: boolean;  
  isBooking?:boolean;
  // requirementsText?: string | string[];
};

const IntlTelInput: FC<InputProps> = ({
  id,
  value,
  title,
  placeholder,
  disabled,
  errText,
  className,
  // setCountryCode,
  setIsValidate,
  onChange,
  onKeyDown,
  onBlur,
  setError,
  preloadedData,
  isFocus,
  isBooking
  // requirementsText,
}) => {
  const countriesList = useSelector(MainObjectSelectors.getSortedCountriesList);
  const inputRef = useRef<HTMLInputElement>(null);
  const countryCodeList = countriesList.map((item) => item.countryCode);
  const localizedCountries = Object.fromEntries(
    countriesList.map((item) => [item.countryCode, item.countryLabel])
  );

  const [isLoaded, setIsLoaded] = useState(false);
  const [intlTel, setIntlTel] = useState<intlTelInput.Plugin>();

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      phone_number: e.target.value.replace(/\D/g, ""),
    });
  };

  const onBlurInput = () => {
    onBlur && onBlur(true);
  };
  useEffect(()=>{
    if(isFocus){
      inputRef?.current?.focus()
      inputRef?.current?.scrollIntoView()
    }
  })
  useEffect(() => {
    const input: any = document.querySelector(`#${id}`);
    const iti = intlTelInput(input, {
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/js/utils.js",
      initialCountry: value.country_code || "by",
      // initialCountry: "auto",
      // geoIpLookup: function (callback) {
      //   fetch("https://ipapi.co/json")
      //     .then(function (res) {
      //       return res.json();
      //     })
      //     .then(function (data) {
      //       callback(data.country_code);
      //     })
      //     .catch(function () {
      //       callback("by");
      //     });
      // },
      onlyCountries: countryCodeList,
      localizedCountries: localizedCountries,
      // autoInsertDialCode: true,
      nationalMode: false,
      separateDialCode: true,
    });

    iti.promise.then(() => {
      setIsLoaded(true);
      setIntlTel(iti);
      onChange({
        ...value,
        phone_code: `+${iti.getSelectedCountryData().dialCode}`,
        country_code: iti.getSelectedCountryData().iso2,
      });
    });
    
    const countryChangeHandler = () => {
      onChange({
        phone_code: `+${iti.getSelectedCountryData().dialCode}`,
        country_code: iti.getSelectedCountryData().iso2,
        phone_number: "",
      });
      setError("");
    };
    
    input.addEventListener("countrychange", countryChangeHandler);
    setIsValidate(!iti.isValidNumber());

    // intlTel
    return () => {
      input.removeEventListener("countrychange", countryChangeHandler);
    };
  }, []);

  useEffect(() => {
    if (intlTel && value.phone_number.length > 0) {
      intlTel.setNumber(value.phone_number);
    }
  }, [intlTel, value.phone_number]);

  useEffect(() => {
    if (intlTel) {
      setIsValidate(!intlTel.isValidNumber());
    }
  }, [intlTel, value.phone_number]);
  
  useEffect(() => {
    const isValidCountry=countryCodeList.find(item=> item===preloadedData?.country_code)
    if (preloadedData && intlTel&& isValidCountry) {
      intlTel.setCountry(preloadedData.country_code)
      onChange(preloadedData)
    }
  }, [intlTel, preloadedData]);

  return (
    <div className={classNames("intlTelInput", isBooking&&"small")}>
      {title && (
        <div className="intlTelInput__title-wrapper">
          <p className="intlTelInput__title">{title}</p>
          {/* {requirementsText && (
            <Tooltip requirementsText={requirementsText} error={!!formatError} />
          )} */}
        </div>
      )}
      <div
        className={classNames({
          "intlTelInput__input-hide": !isLoaded,
        })}
      >
        <input
          value={value.phone_number}
          className={classNames("intlTelInput__input", className, {
            "intlTelInput__input-active": value.phone_number.length > 0,
            "intlTelInput__input-error": errText,
            "intlTelInput__input-disabled": disabled,
          })}
          id={id}
          type="tel"
          placeholder={placeholder}
          onChange={onChangeText}
          onBlur={onBlurInput}
          disabled={disabled}
          onKeyDown={onKeyDown}
          ref={inputRef}   
           
        />
      </div>
      {errText && <p className="intlTelInput__errText">{errText}</p>}
    </div>
  );
};

export default IntlTelInput;
