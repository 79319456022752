
import { BookingFormType, BookingType } from "../../../redux/types/bookingTypes";
import { CloseIcon } from "../../../assets/icons";
import { getTitle } from "../functions";
import Button from "../../Button";
import { ButtonType, ModalWindowTypes } from "../../../utils/@globalTypes";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import classNames from "classnames";
import { BookingFormSelectors, resetBookingForm } from "../../../redux/reducers/bookingFormSlice";

import styles from "./BookingHeader.module.scss";

type HeaderProps ={
    windowType: BookingFormType,
    handleClickEdit?:()=> void,     
    handleClickSave?:()=>void,   
    handleClickDelete?:()=> void,  
    isLast?:boolean,
    isReadyToDelete?:boolean,
}

const BookingHeader = ({
    windowType, 
    handleClickEdit, 
    handleClickSave, 
    handleClickDelete,
    isLast,
    isReadyToDelete
    }:HeaderProps) => {
        
    const title = getTitle(windowType);

    const dispatch = useDispatch();
    const {
        isActiveSaveButton
      } = useSelector(BookingFormSelectors.getBookingForm)  

    const handlerClose = () =>{
        dispatch(resetBookingForm())
        dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    }
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {title}
                {windowType===BookingFormType.edit && 
                    <span className={styles.titleAdd}>(Режим редактирования)</span>
                }
            </div>
            <div className={styles.btnPanel}>
                {windowType===BookingFormType.view && !isLast&& 
                <div className={styles.btnEdit}>
                    <Button 
                        title="Редактировать" 
                        type={ButtonType.PRIMARY_SMALL}
                        onClick={handleClickEdit}
                        />
                </div>}
                {!isLast&& <div className={styles.btnSave}>
                    <Button 
                        title="Сохранить" 
                        type={ButtonType.PRIMARY_SMALL} 
                        onClick={handleClickSave}
                        disabled={!isActiveSaveButton}
                    />
                        
                </div>}
                {windowType!==BookingFormType.new &&  
                <div className={classNames(styles.btnDel, isReadyToDelete&&styles.btnActiveDel)}>
                    <Button 
                        title="Удалить" 
                        type={ButtonType.PRIMARY_SMALL}
                        onClick={handleClickDelete}
                        disabled={!isReadyToDelete}
                        confirmWindowText="Вы действительно хотите удалить бронь?"
                        className="deleteBookingList"
                        // wrapperClassName="bookingList"
                        windowClassName="bookingFormConfirm"
                    />
                    {!isReadyToDelete&&<div className={styles.help}>
                        Для удаления брони необходимо изменить ее статус на "Отменена"
                    </div>}    
                </div>}
                <div className={styles.btnClose} onClick={handlerClose}>
                    <CloseIcon/>
                </div>
                
            </div>

        </div>
    )
}
export default BookingHeader ;