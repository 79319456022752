import {
  BalconyIcon,
  DiamondIcon,
  DishesIcon,
  DishwasherIcon,
  FlatironIcon,
  FridgeIcon,
  FurnitureForInfantsIcon,
  HairdryerIcon,
  KitchenIcon,
  MicrowaveIcon,
  NoSmokingIcon,
  ParkingIcon,
  PatioIcon,
  PierIcon,
  PlateIcon,
  ShowerIcon,
  StarIcon,
  TVIcon,
  TerraceIcon,
  WashingMachineIcon,
  WifiIcon,
  СonditionerIcon,
} from "../assets/features_icons";
import { GrillIcon } from "../assets/features_icons/GrillIcon";

export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const CURRENCY = "BYN";

// REGEXP

export const NAME_REG = /^[A-Za-z]+(-[A-Za-z]+)?$|^[А-ЯЁа-яё]+(-[А-ЯЁа-яё]+)?$/;
export const MIDDLENAME_REG = /^([A-Za-z]+)$|^([А-ЯЁа-яё]+)$/;
export const EMAIL_REG = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
export const TELEGRAM_REG = /^([A-Za-z0-9\s!\?@#\$%\^&\*\(\)\[\]{}<>,\._'\+=\|\/":;№-]+){0,1}$/;
export const URL_REG = /^http(s)?:\/\/([0-9a-z]+[~_\.-]?[0-9a-z]+)+\.([a-z]{2,6})\/?$/;
export const OBJECT_NAME_REG = {
  isLetter: /[A-Za-zА-ЯЁа-яё]/,
  length: /^[A-Za-zА-ЯЁа-яё0-9\s!\?@#\$%\^&\*\(\)\[\]{}<>,\._'\+=\|\/"№-]{3,60}$/,
};
export const ADDRESS_REG = {
  isLetter: /[A-Za-zА-ЯЁа-яё]/,
  length: /^[A-Za-zА-ЯЁа-яё0-9\s!\?@#\$%\^&\*\(\)\[\]{}<>,\._'\+=\|\/":;№-]{3,60}$/,
};
export const FEATURE_NAME_REG = {
  isLetter: /[A-Za-zА-ЯЁа-яё]/,
  length: /^[A-Za-zА-ЯЁа-яё0-9\s!\?@#\$%\^&\*\(\)\[\]{}<>,\._'\+=\|\/"№-]{0,30}$/,
};
export const ROOM_NAME_REG = {
  isLetter: /[A-Za-zА-ЯЁа-яё]/,
  length: /^[A-Za-zА-ЯЁа-яё0-9\s!\?@#\$%\^&\*\(\)\[\]{}<>,\._'\+=\|\/"№-]{0,50}$/,
};
export const PASS_REG = {
  isUppercaseLetter: /[A-Z]/,
  isLowercaseLetter: /[a-z]/,
  isDigit: /[0-9]/,
  isSymbol: /[!\?@#\$%\^&\*\(\)\[\]{}<>,\._'\+=\|\/"-]/,
  minLength: /^[A-Za-z0-9!\?@#\$%\^&\*\(\)\[\]{}<>,\._'\+=\|\/"-]{8,}$/,
};
export const POSITION_REG = {
  isLetter: /[A-Za-zА-ЯЁа-яё]/,
  length: /^[A-Za-zА-ЯЁа-яё0-9\s!\?@#\$%\^&\*\(\)\[\]{}<>,\._'\+=\|\/"-]{3,50}$/,
};

// Olya
export const IND_TAXPAYER_NUMBER_REG = {
  isDigit: /[0-9]/,
  length: /^[(A-Z0-9]{7,14}$|^[0-9]{7,14}$/,
};
export const ENT_TAXPAYER_NUMBER_REG = /^[0-9]{8,14}$/;

export const ANIMAL_INFO_REG = {
  isLetter: /[A-Za-zА-ЯЁа-яё]/,
  length: /^[A-Za-zА-ЯЁа-яё0-9\s!\?@#\$%\^&\*\(\)\[\]{}<>,\._'\+=\|\/"-]{0,100}$/,
};

// ERRORS

const getFieldError = (fieldName: string) => {
  return `${fieldName} не соответствует рекомендациям, указанным в подсказке`;
};

export const REQUIRED_FIELD_ERROR = "Поле обязательно для заполнения";

export const FIRST_NAME_ERROR = getFieldError("Имя");
export const LAST_NAME_ERROR = getFieldError("Фамилия");
export const MIDDLE_NAME_ERROR = getFieldError("Отчество");

export const EMAIL_ERROR = "Не соответствует формату электронной почты";
export const EMAIL_ALREADY_EXISTS_ERROR = "Пользователь с таким адресом уже существует";

export const TELEPHONE_ERROR = "Не соответствует формату мобильного телефона";
export const OBJECT_NAME_ERROR = getFieldError("Название объекта");
export const URL_ERROR = "Не соответствует формату адреса сайта";

export const PASS_ERROR = getFieldError("Пароль");
export const CONFIRM_PASS_ERROR = "Пароли не совпадают";
export const CREDENTIALS_ERROR = "Пользователь с такой электронной почтой или паролем не найден";

export const POSITION_ERROR = getFieldError("Должность");

export const REGION_ERROR = getFieldError("Административное деление");
export const LOCALITY_ERROR = getFieldError("Название населенного пункта");
export const ADDRESS_ERROR = getFieldError("Адрес");

export const IMAGE_ERROR = "Фото не соответствует рекомендациям, указанным в подсказке";

export const INVALID_CODE_ERROR = "Введен неверный код активации. Проверьте код и введите снова";
export const EXPIRED_CODE_ERROR =
  "Срок действия кода истек. Для генерации нового кода активации нажмите на кнопку “Получить код еще раз”";

export const RENTAL_DESCRIPTION_ERROR = getFieldError("Текстовое описание");
export const FEATURE_NAME_ERROR = getFieldError("Наименование удобства");

export const ROOM_NAME_ERROR = getFieldError("Название комнаты");

export const TAXPAYER_NUMBER_ERROR = getFieldError("Номер налогоплательщика");
export const ENTITY_NAME_ERROR = getFieldError("Наименование");

export const TOTAL_INFO_ERROR = "Не соответствует рекомендациям, указанным в подсказке";

// REQUIREMENTS

export const FIRST_NAME_REQ = [
  "Имя должно содержать русские или латинские буквы и состоять из одного слова.",
  "Если у вас двойное имя, допускается использование «-» без пробелов, например Анна-Мария.",
];
export const LAST_NAME_REQ = [
  "Фамилия должна содержать русские или латинские буквы и состоять из одного слова.",
  "Если у вас двойная фамилия, допускается использование «-» без пробелов, например Римский-Корсаков.",
];
export const MIDDLE_NAME_REQ = ["Отчество должно содержать русские или латинские буквы."]; // ?
export const URL_REQ =
  "Укажите точный адрес своего ресурса с корректным префиксом (http:// или https://)";
export const PASS_REQ = [
  "Пароль должен состоять минимум из 8-и символов.",
  "Содержать латинские буквы верхнего и нижнего регистра, цифры и спецсимволы.",
  "В пароле должны быть минимум 1 цифра, 1 заглавная буква, 1 строчная буква, 1 спецсимвол (!?@#$%^&*()[]{}<>,._'-+=|/\")",
];
const getFieldReq = (
  isMaxOnly: boolean,
  fieldName: string,
  maxLength: string,
  minLength?: string
) => {
  return [
    `${fieldName} содержать русские и/или латинские буквы, а также может содержать цифры и спецсимволы.`,
    isMaxOnly
      ? `${fieldName} состоять максимум из ${maxLength} символов, включая пробелы.`
      : `${fieldName} состоять минимум из ${minLength} символов и максимум из ${maxLength}, включая пробелы.`,
  ];
};
export const OBJECT_NAME_REQ = getFieldReq(false, "Название должно", "60-ти", "3-х");
export const POSITION_NAME_REQ = getFieldReq(false, "Должность должна", "50-ти", "3-х");
export const REGION_REQ = getFieldReq(false, "Административное деление должно", "60-ти", "3-х");
export const LOCALITY_REQ = getFieldReq(
  false,
  "Название населенного пункта должно",
  "60-ти",
  "3-х"
);
export const ADDRESS_REQ = getFieldReq(false, "Адрес должен", "60-ти", "3-х");
export const ROOM_NAME_REQ = getFieldReq(true, "Название должно", "50-ти");

export const MAIN_OBJECT_IMAGE_REQ = [
  "Формат изображения .jpg, допускается .png",
  "Размер файла не более 1 Мб",
  "Максимальная длина изображения 800px",
  "Максимальная высота изображения 600px",
  "Ориентация изображения горизонтальная",
];

export const MAIN_OBJECT_BOOKING_IMAGE_REQ = [
  "Формат изображения .jpg, допускается .png",
  "Размер файла не более 1 Мб",
  "Максимальная длина изображения 1920px",
  "Максимальная высота изображения 1080px",
  "Ориентация изображения горизонтальная",
];

export const RENTAL_DESCRIPTION_REQ = ["Максимальное количество символов - 550, включая пробелы"];
export const FEATURE_NAME_REQ = [
  "Наименование удобства должно содержать русские и/или латинские буквы, а также может содержать цифры и спецсимволы",
  "Максимальное количество символов - 30, включая пробелы",
];

export const IND_TAXPAYER_NUMBER_REQ = [
  "Латинские заглавные буквы допускаются",
  "Заполнение поля только заглавными латинскими буквами не допускается",
  "Спецсимволы не допускаются",
  "Цифры допускаются",
  "Общее количество символов 7-14",
];
export const ENT_TAXPAYER_NUMBER_REQ = ["Номер должен содержать 8-14 цифр"];

export const AVATAR_REQ = ["Формат изображения .jpg, .png", "Размер файла не более 1 Мб"];
export const ANIMALINFO_REQ = [
  "Допускаются буквы",
  "Допускаются цифры и спецсимволы",
  "Максимальное количество символов - 100",
];
export const TELEGRAM_REQ = [
  "Латинские буквы в верхнем и нижнем регистре",
  "Цифры допускаются",
  "Спецсимволы допускаются",
];
export const COMMENT_REQ = [
  "Допускаются буквы",
  "Допускаются цифры и спецсимволы",
  "Максимальное количество символов - 550",
];
export const RENTAL_OBJECT_IMAGES_REQ = [
  "Максимальное количество загружаемых фото - 9",
  "Формат изображения .jpg, допускается .png",
  "Размер файла не более 1 Мб",
  "Максимальная длина изображения 800px",
  "Максимальная высота изображения 600px",
  "Ориентация изображения горизонтальная",
];

// TIME

export const TIME_CODE_CONFIRMATION_SENDING = 60;
export const TIME_BLOCK_USER = 20;

// Feature icons

export const FEATURES_OPTIONS = [
  { value: "kitchen", label: <KitchenIcon /> },
  { value: "dishes", label: <DishesIcon /> },
  { value: "plate", label: <PlateIcon /> },
  { value: "fridge", label: <FridgeIcon /> },
  { value: "microwave", label: <MicrowaveIcon /> },
  { value: "dishwasher", label: <DishwasherIcon /> },
  { value: "wifi", label: <WifiIcon /> },
  { value: "conditioner", label: <СonditionerIcon /> },
  { value: "balcony", label: <BalconyIcon /> },
  { value: "parking", label: <ParkingIcon /> },
  { value: "flatiron", label: <FlatironIcon /> },
  { value: "furnitureForInfants", label: <FurnitureForInfantsIcon /> },
  { value: "grill", label: <GrillIcon /> },
  { value: "hairdryer", label: <HairdryerIcon /> },
  { value: "noSmoking", label: <NoSmokingIcon /> },
  { value: "patio", label: <PatioIcon /> },
  { value: "pier", label: <PierIcon /> },
  { value: "shower", label: <ShowerIcon /> },
  { value: "terrace", label: <TerraceIcon /> },
  { value: "tv", label: <TVIcon /> },
  { value: "washingMachine", label: <WashingMachineIcon /> },
  { value: "star", label: <StarIcon /> },
  { value: "diamond", label: <DiamondIcon /> },
];

// Olya
export const TaxPayerNumberList = [
  { value: "by", label: "Введите УНП" },
  { value: "ru", label: "Введите ИНН" },
  { value: "ua", label: "Введите ИНН" },
  { value: "kz", label: "Введите ИИН" },
  { value: "am", label: "Введите УНН/НЗОУ" },
  { value: "az", label: "Введите ИНН/ПИК" },
  { value: "ge", label: "Введите номер VAT" },
  { value: "kg", label: "Введите ИНН" },
  { value: "md", label: "Введите ИНФ (IDNP)/ ФК, присвоенный ГНС" },
  { value: "tj", label: "Введите ИНН" },
  { value: "uz", label: "Введите ИНН" },
  { value: "lv", label: "Введите ПК (TIN)" },
  { value: "lt", label: "Введите ИН" },
  { value: "ee", label: "Введите УЛК" },
  { value: "pl", label: "Введите номер NIP" },
];
export const EntityTaxNumberList = [
  { value: "by", label: "Введите УНП" },
  { value: "ru", label: "Введите ИНН" },
  { value: "ua", label: "Введите ИНН" },
  { value: "kz", label: "Введите БИН" },
  { value: "am", label: "Введите УНН" },
  { value: "az", label: "Введите ИНН" },
  { value: "ge", label: "Введите номер VAT" },
  { value: "kg", label: "Введите ИНН" },
  { value: "md", label: "Введите ГИН (IDNO)" },
  { value: "tj", label: "Введите ЕИН" },
  { value: "uz", label: "Введите ИНН" },
  { value: "lv", label: "Введите РН" },
  { value: "lt", label: "Введите ИНП" },
  { value: "ee", label: "Введите РК" },
  { value: "pl", label: "Введите номер NIP" },
];
