import React, { FC } from "react";
import styles from "./RentalObjectCard.module.scss";
import Allowed from "../Allowed/Allowed";
import Button from "../Button/Button";
import { ButtonType, TabsNames } from "../../utils/@globalTypes";
import { DeleteIcon, EditIcon, Home2Icon } from "../../assets/icons";
import { RoutesList } from "../../App";
import Loader from "../Loader";

type RentalObjectCardProps = {
  id: number;
  mainObjectId: string;
  name: string;
  description: string;
  status: string;
  photo: string | null;
  onDeleteBtnClick: () => void;
  isLoading: boolean;
};

const RentalObjectCard: FC<RentalObjectCardProps> = ({
  id,
  mainObjectId,
  name,
  description,
  status,
  photo,
  onDeleteBtnClick,
  isLoading,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <div>
          <h3 className={styles.title}>{name}</h3>
          <Allowed status={status} />
        </div>
        <div className={styles.btnsWrapper}>
          <Button
            type={ButtonType.SMALL}
            title={<DeleteIcon />}
            onClick={onDeleteBtnClick}
            confirmWindowText="Действительно хотите удалить?"
          />
          <Button
            type={ButtonType.SMALL}
            title={<EditIcon />}
            path={`${RoutesList.objectManagement.fullPath}/${mainObjectId}/${RoutesList.objectManagement.rentalObjectEditingChild.part}/${id}/${TabsNames.RENTAL_BASIC_SETTINGS}`}
          />
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.imgWrapper}>
          {photo ? <img src={photo} alt={name} /> : <Home2Icon />}
        </div>
        <p className={styles.description}>{description || "Описание отсутствует"}</p>
      </div>
      {isLoading && (
        <div className={styles.loaderOverlay}>
          <Loader size={50} />
        </div>
      )}
    </div>
  );
};

export default RentalObjectCard;
