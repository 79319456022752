import React, { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import moment from 'moment';
import 'moment/locale/ru';
// import {bookingArray} from "./fakeData";

import CheckerboardBanner from "../CheckerboardBanner";
import { Arrow2Icon } from "../../assets/icons";
import { useSelector } from "react-redux";
import bookingSlice, { BookingSelectors } from "../../redux/reducers/bookingSlice";


import styles from "./CheckerboardTable.module.scss";
import { MainObjectShort } from "../../redux/types/bookingTypes";

type Rental_object ={
  id:number,
  title:string
}
type ObjectState = {
  id:number,
  value:boolean
}
type CheckerboardTableProps= {
  period: moment.Moment[],
  today: moment.Moment,
  scrollPosition:number,
  itemWidth:number,
  isForceUpdate:boolean,
  cancelForce:()=>void,
  objects:MainObjectShort[],
  selectedDate:Date
}

const CheckerboardTable:FC<CheckerboardTableProps> = ({
  period, 
  today, 
  scrollPosition, 
  itemWidth, 
  isForceUpdate, 
  cancelForce,
  objects,
  selectedDate
}) => {
  
  const bookingArray = useSelector(BookingSelectors.getBookingList);
  
  const containerWidth = 1000;   //размер окна просмотра
  const overscan = 5;       // рендер "запасных" ячеек при виртуальном скролле

  const [scrollLeft, setScrollLeft] = useState(scrollPosition);
  const scrollElementRef = useRef<HTMLDivElement>(null);
  
  useEffect(()=>{
    if(scrollElementRef.current!.scrollLeft!==scrollPosition){
      scrollElementRef.current!.scrollLeft=scrollPosition; 
    }
  },[scrollPosition])

  useEffect(()=>{
    if(isForceUpdate){
      scrollElementRef.current!.scrollLeft=scrollPosition; 
    }
  },[isForceUpdate])

  useLayoutEffect(()=>{
    const scrollElement = scrollElementRef.current

    if(!scrollElement){
      return
    }

    const handleScroll = () =>{
      const scrollLeft = scrollElement.scrollLeft;
      setScrollLeft(scrollLeft);
      cancelForce();
    }

    handleScroll();

    scrollElement.addEventListener('scroll', handleScroll);

    return () => scrollElement.removeEventListener('scroll', handleScroll);

  },[])
 
  const virtualItems = useMemo(()=> {       // определяем какие элементы будут отрендерены для окна видимости (виртуальный скролл)
    const rangeStart = scrollLeft;
    const rangeEnd = scrollLeft + containerWidth;

    let startIndex = Math.floor(rangeStart / itemWidth);
    let endIndex = Math.ceil(rangeEnd / itemWidth);

    startIndex = Math.max(0, startIndex - overscan);
    endIndex = Math.min(period.length-1, endIndex + overscan);
    
    const virtualItems =[]
    for (let index=startIndex; index<=endIndex; index++){
      virtualItems.push({
        index:index,
        offsetLeft:index*itemWidth+248
      })
    }

    return virtualItems

  },[scrollLeft, period.length])

  const totalTableWidth = period.length*itemWidth+250  //общая ширина окна таблицы для отображения скролла

  const [openObject, setOpenObject] = useState<ObjectState[]>([]); //state для боковой части таблицы: какие элементы закрыты или открыты

  useEffect(()=>{
    objects&&objects.forEach((item)=>{
      const objId = item.id;
      setOpenObject(state =>[...state,{id:objId, value:true}]) //начальное положение таблицы: все объекты раскрыты      
    })
  },[])
  
  const handlerClick =(id:number) =>{  //обрабатываем смену state таблицы: раскрыты или скрыты объекты 
    const current=openObject.findIndex(item=> item.id===id)
    
    if(current>-1){
     setOpenObject(prevState=> {
      const newObjects = prevState.map(item=>{return item})      
      newObjects[current].value=!newObjects[current].value     
      return(
        newObjects
      )
     })
    }    
  }

  const aside=objects&&objects.map((item)=>{   // боковое меню таблицы
    const innerObject =item.rental_objects.length>0&& item.rental_objects.map((obj)=> { //вложенные в основной объект сдаваемые объекты
      return(
        <div className={styles.object} key={`R${obj.id}`}>
          <div>{obj.name}</div>          
        </div>      
      )
    })
    const current = openObject.find(itemValue=> itemValue.id===item.id)
    const display = current?.value||false   // находим state для данного основного объекта
    return(
      <React.Fragment key={item.id}>
        <div className={classNames(styles.object, styles.header)} >
          <div>{item.name}</div>
          <span className={classNames(styles.arrow, display&&styles.open)} onClick={()=>handlerClick(item.id)}><Arrow2Icon/></span>
        </div>   
        {display&&innerObject}    
      </React.Fragment>   
    )
  })  
  const filterBookingWithStatus = bookingArray&&bookingArray.filter(item=> item.status==="cfm"||item.status==="awt")
  const startData = period[virtualItems[0].index] // первая дата периода
  const longBooking = filterBookingWithStatus&&filterBookingWithStatus.filter(item=> moment(item.check_in_date).startOf('day')<startData&&moment(item.check_out_date).startOf('day')>startData)
   
  const dates=virtualItems.map((virtualItem, index)=>{  // создаем массив ДОМ-элементов для рендеринга столбцов с датами (виртуальный скролл)
    const item = period[virtualItem.index]
        
    const filterBookingForDay = filterBookingWithStatus&&filterBookingWithStatus.filter(item=> moment(item.check_in_date).startOf('day').isSame(period[virtualItem.index].startOf('day')))    
    
    return(
      <div key={item.unix()} className={styles.column}   //рисуем столбец для даты
        style={{
          width:itemWidth,
          position:'absolute',
          left:virtualItem.offsetLeft
        }}
      >      
        <div  className={classNames(
          styles.tableGrid, 
          moment(selectedDate).startOf('day').isSame(item.startOf('day'))&&styles.selectDate,
          styles.head, 
          item.dayOfYear()===today.dayOfYear()&&styles.today
        )} id={item.unix().toString()}
          style={{
            width:itemWidth
          }}
          >
          <div className={styles.headDate}>{item.format('D.MM.YY')}</div>  
          <div className={styles.dayOfWeek}> {item.format('dd')}</div>  
        </div>

        {objects&&objects.map((object)=>{
          const current = openObject.find(itemValue=> itemValue.id===object.id)
          const display = current?.value||false     //
          const id = `obj${object.id}_day${item.startOf('day').unix()}`
          
          const innerObject = object.rental_objects.map((obj)=> {
            const filterBookingForObject = filterBookingForDay&&filterBookingForDay.find(item=> item.rental_object===obj.id) 
            const filterLongBookingForObject = (index===0) ? longBooking&&longBooking.find(item=> item.rental_object===obj.id) : null
            return(
              <div className={classNames(styles.tableGrid) } key={`RO${obj.id}`} id={id}
                style={{
                  width:itemWidth                  
                }} 
              >
              {filterBookingForObject && 
                <CheckerboardBanner 
                  booking={filterBookingForObject} 
                  cellWidth={itemWidth} 
                  key={index}
                  currentDay={item}
                  type="start"
                />}
              {filterLongBookingForObject && 
                <CheckerboardBanner 
                  booking={filterLongBookingForObject} 
                  cellWidth={itemWidth} 
                  key={index}
                  currentDay={item}
                  type="ending"
                />}
            </div>    
            )
          })        
          return(
            <React.Fragment key={object.id}>
            <div className={classNames(styles.tableGrid)} 
              style={{width:itemWidth}} id={id}
            >
              
            </div>
            {display&&innerObject}
            </React.Fragment>
          )
        })}
      </div>
    )})     
   
  return(
    <div className={styles.container} >
             
      <div className={styles.tableWrapper} ref={scrollElementRef} >
        <div style={{width:totalTableWidth}} className={styles.table}>
          <div className={styles.aside}>
            <div className={styles.tableHeadNotation}>
                <div className={styles.headRight}>Дата </div>
                <div className={styles.headLeft}>Объект</div>
            </div>
            {aside}
          </div> 
        {dates}
                
        </div>
      </div>
      
    </div>
  )
}

export default CheckerboardTable;