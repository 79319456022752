import classNames from 'classnames';
import { BookingFormType } from '../../../redux/types/bookingTypes';
import BookingLineTitle from '../BookingLineTitle';
import { Arrow2Icon } from '../../../assets/icons';
import { useEffect, useState } from 'react';
import SelectComponentV2 from '../../SelectComponentV2';
import { ButtonType } from '../../../utils/@globalTypes';
import Button from '../../Button';
import { agesListOption } from '../functions';

import styles from './PersonCount.module.scss';

type CountProps ={
  windowType:BookingFormType,
  adult?:number,
  children?:number[],
  setAdult:React.Dispatch<React.SetStateAction<number>>,
  setKids:React.Dispatch<React.SetStateAction<number[]>>,  
}

const PersonCount = ({
  windowType, 
  adult, 
  children,
  setAdult,
  setKids
}:CountProps) => {
  const [isOpen, setIsopen]=useState(false);
  
  const [adultCountAdd, setAdultcountAdd]=useState(1);
  
  const [kidsCountAdd, setKidscountAdd]=useState<string[]>([]);

  // useEffect(() => {    
  //   adult&&setAdultcount(adult)    
  //   children&&setKidscount(children)    
  // }, [adult, children]); 
  
  useEffect(() => { 
    windowType===BookingFormType.view&& setIsopen(false)
  }, [windowType]); 
  const adultTitle = (adult===1||!adult) ? "1 взрослый": `${adult} взрослых`;
  const childrenTitle = children&&children.length>0 ? children.length===1 ?
  " и 1 ребенок" : ` и ${children.length} детей` : "";

  const handlerIsOpen = () =>{
    if(!isOpen){
      setIsopen(true)
      adult&&setAdultcountAdd(adult)   
      const newKids =children&& children.map(item=> item.toString())
      newKids&&setKidscountAdd( newKids )
    } else {
      setIsopen(false)
    }
    
  }

  const increaseAdult = () =>{
    if(adultCountAdd<50){
      setAdultcountAdd(adultCountAdd+1)
    }
  }
  const decreaseAdult = () =>{
    if(adultCountAdd>1){
      setAdultcountAdd(adultCountAdd-1)
    }
  }
  const increaseKids = () =>{
    if(kidsCountAdd.length<20){
      const newKids = [...kidsCountAdd] 
      newKids.push("0")
      setKidscountAdd(newKids)
    }
  }
  const decreaseKids = () =>{
    if(kidsCountAdd.length>0){
      const newKids = [...kidsCountAdd] 
      newKids.pop();
      setKidscountAdd(newKids)
    }
  }
  const handlerSave = () =>{    
    setAdult(adultCountAdd);
    const newKids = kidsCountAdd.map(item=> Number(item))
    setKids(newKids)
    setIsopen(false);      
    }
   
  const kidsAges =kidsCountAdd.length>0 && kidsCountAdd.map((age, index)=>{
    const handlerSelecValue=(value:string)=>{
      const newArr=[...kidsCountAdd]
      newArr[index]=value
      setKidscountAdd(newArr)
    }
    return (
      <div key={index} className={styles.select}>
        <SelectComponentV2
          title={`Возраст ${index+1}-го ребенка`}
          placeholder=''
          currentValue={age}
          setSelecValue={handlerSelecValue}
          optionsList={agesListOption}
          isBooking
        />
      </div>
    )
  })
  return (
    <div className={styles.wrap}>
      <BookingLineTitle title={windowType===BookingFormType.view ? "Количество гостей" : "Количество гостей*"}/>
      <div className={classNames(
        styles.mainWindow, 
        isOpen && styles.active,
        isOpen&&styles.open,
        windowType===BookingFormType.view &&styles.view
        )}>
        <div>{adultTitle}{childrenTitle}</div>
        {windowType!==BookingFormType.view&&<div className={styles.arrow} onClick={handlerIsOpen}>
          <Arrow2Icon height='25px'/>
        </div>}
      </div>
      {isOpen&&windowType!==BookingFormType.view&&
        <div className={styles.addWindow}>
          <div className={styles.countLine}>
            <div className={styles.countTitle}>
              Количество взрослых
            </div>
            <div className={styles.counter}>
              <div className={styles.sign} onClick={decreaseAdult}>
                -
              </div>
              <div className={styles.number}>
                {adultCountAdd}
              </div>
              <div className={styles.sign} onClick={increaseAdult}>
                +
              </div>
            </div>
          </div>
          <div className={styles.countLine}>
            <div className={styles.countTitle}>
              Количество детей
            </div>
            <div className={styles.counter}>
              <div className={styles.sign} onClick={decreaseKids}>
                -
              </div>
              <div className={styles.number} >
                {kidsCountAdd.length}
              </div>
              <div className={styles.sign} onClick={increaseKids}>
                +
              </div>
            </div>
          </div>
          {kidsAges}
          <div className={styles.btnLine}>
            <Button title ="Готово" type={ButtonType.PRIMARY_SMALL} onClick={handlerSave}/>
          </div>
        </div>
      }
    </div>
  )
}

export default PersonCount ;