import { ChangeEvent } from "react";
import classNames from "classnames";
import { IMaskInput } from "react-imask";

import styles from "./InputSum.module.scss";

type MyProps ={
  summ?:string;
  isDisabled?:boolean;
  onChange?: (value: string) => void;
  onBlur?:() => void;
  isError?: boolean;
  min:number;
  max:number;
}
const InputSum = ({
  summ, 
  isDisabled, 
  onChange, 
  onBlur, 
  min,
  max,
  isError}:MyProps 
) => { 
 
  return (      
    <IMaskInput
      mask={Number}        
      className={classNames(styles.input, isDisabled&&styles.disabled, isError&& styles.error)}
      value={summ}
      min={min}
      max={max}
      readOnly={isDisabled}
      onAccept={(value, unmaskedValue, mask) => {
        onChange&& onChange(unmaskedValue._unmaskedValue)        
      }}
      scale={2}
      padFractionalZeros={true}
      thousandsSeparator={" "}        
      onBlur={onBlur}      
      unmask={true}
      radix="."
      
    />     
  )
}

export default InputSum ;