import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BookingFormType, CreateBookingType, UpdateBookingType } from "../../../redux/types/bookingTypes";
import BookingContactBlock from "../BookingContactBlock";
import BookingHeader from "../BookingHeader";
import BookingObjectBlock from "../BookingObjectBlock";
import BookingPersonBlock from "../BookingPersonBlock";
import BookingCommentBlock from "../BookingCommentBlock";

import styles from "./NewBookingContainer.module.scss";
import { createBooking, getBookingList } from "../../../redux/reducers/bookingSlice";
import { 
  BookingFormSelectors, 
  resetBookingForm, 
  startSave, completeSave, 
  hasErrors,
  completeErrors
 } from "../../../redux/reducers/bookingFormSlice";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import { ModalWindowTypes } from "../../../utils/@globalTypes";

const NewBookingContainer = () => { 
  const dispatch = useDispatch();
  const {
    commentBlockValid, 
    objectBlockValid,
    personBlockValid,
    contactBlockValid,
    isStartSave,
    isHasErrors
  } = useSelector(BookingFormSelectors.getBookingForm)  
  
  const handleClickSave = ()=>{
    dispatch(startSave())      
  }

  useEffect(()=> {   
    dispatch(resetBookingForm())
  },[])
  
  useEffect(()=> {    
    if (isStartSave&& objectBlockValid&& personBlockValid && contactBlockValid&& commentBlockValid){
      
      console.log(objectBlockValid.isValid, personBlockValid.isValid, contactBlockValid.isValid, commentBlockValid.isValid)      
      if(
        objectBlockValid.isValid &&
        personBlockValid.isValid &&
        contactBlockValid.isValid &&
        commentBlockValid.isValid         
      ){
        dispatch(completeErrors())         
        const newBooking:CreateBookingType = {
          phone: {
            country_code: "",
            phone_code: "",
            phone_number: ""
          },
          check_in_date: "",
          check_out_date: "",
          check_in_time: "",
          check_out_time: "",
          adult: 0,
          total: "",
          paid: "",
          has_animals: false,
          status: "new",
          first_name: "",
          last_name: "",
          nationality: "",
          email: "",
          rental_object: 0
        };
        objectBlockValid.update.rental_object&&(newBooking.rental_object=objectBlockValid.update.rental_object);
        objectBlockValid.update.status&&(newBooking.status=objectBlockValid.update.status);
        objectBlockValid.update.total&&(newBooking.total=objectBlockValid.update.total);
        objectBlockValid.update.paid&&(newBooking.paid=objectBlockValid.update.paid);
        objectBlockValid.update.has_animals&&(newBooking.has_animals=objectBlockValid.update.has_animals);
        objectBlockValid.update.animals_info&&(newBooking.animals_info=objectBlockValid.update.animals_info);
        objectBlockValid.update.check_in_date&&(newBooking.check_in_date=objectBlockValid.update.check_in_date);
        objectBlockValid.update.check_out_date&&(newBooking.check_out_date=objectBlockValid.update.check_out_date);
        objectBlockValid.update.check_in_time&&(newBooking.check_in_time=objectBlockValid.update.check_in_time);
        objectBlockValid.update.check_out_time&&(newBooking.check_out_time=objectBlockValid.update.check_out_time);
        objectBlockValid.update.adult&&(newBooking.adult=objectBlockValid.update.adult);
        objectBlockValid.update.children&&(newBooking.children=objectBlockValid.update.children);
        personBlockValid.update.first_name&&(newBooking.first_name=personBlockValid.update.first_name);
        personBlockValid.update.last_name&&(newBooking.last_name=personBlockValid.update.last_name);
        personBlockValid.update.second_name&&(newBooking.second_name=personBlockValid.update.second_name);
        personBlockValid.update.nationality&&(newBooking.nationality=personBlockValid.update.nationality);
        personBlockValid.update.sex&&(newBooking.sex=personBlockValid.update.sex);
        personBlockValid.update.birth_day&&(newBooking.birth_day=personBlockValid.update.birth_day);
        contactBlockValid.update.email&&(newBooking.email=contactBlockValid.update.email);
        contactBlockValid.update.telegram&&(newBooking.telegram=contactBlockValid.update.telegram);
        contactBlockValid.update.phone?.country_code&&(newBooking.phone.country_code=contactBlockValid.update.phone?.country_code);
        contactBlockValid.update.phone?.phone_code&&(newBooking.phone.phone_code=contactBlockValid.update.phone?.phone_code);
        contactBlockValid.update.phone?.phone_number&&(newBooking.phone.phone_number=contactBlockValid.update.phone?.phone_number);
        commentBlockValid.update.comment&&(newBooking.comment=commentBlockValid.update.comment);      
        console.log(newBooking);
        console.log ("Все получилось, можно сохраняться")     
        dispatch(createBooking(newBooking));
        dispatch(completeSave())     
        
      }  else {
          console.log("что-то пошло не так")         
          dispatch(hasErrors())    
          dispatch(completeSave()) 
      }
    }  
  }, [isStartSave, objectBlockValid, personBlockValid, contactBlockValid, commentBlockValid]);

  return (
    <>
      <BookingHeader 
        windowType ={BookingFormType.new}
        handleClickSave={handleClickSave}            
      />
        <main className={styles.mainContainer}> 
          <BookingObjectBlock 
            windowType ={BookingFormType.new}                       
          />
          <BookingPersonBlock 
            windowType ={BookingFormType.new}                 
          />
          <BookingContactBlock 
            windowType ={BookingFormType.new}                    
          />
          <BookingCommentBlock
            windowType ={BookingFormType.new}                       
          />
        </main>
    </>

  )
}

export default NewBookingContainer ;