import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UpdateBookingType } from "../types/bookingTypes";

type BookingBlockUpdate = {
  isValid:boolean, 
  update:UpdateBookingType
}

type BookingFormState = {
  isActiveSaveButton: boolean;  
  isStartSave: boolean;
  isHasErrors: boolean;
  objectBlockValid: BookingBlockUpdate|null;
  personBlockValid: BookingBlockUpdate|null;
  contactBlockValid: BookingBlockUpdate|null;
  commentBlockValid: BookingBlockUpdate|null;
};

const initialState: BookingFormState = {
  isActiveSaveButton: false,  
  isStartSave: false,
  isHasErrors: false,
  objectBlockValid: null,
  personBlockValid: null,
  contactBlockValid: null,
  commentBlockValid: null,
};

const BookingFormSlice = createSlice({
  name: "bookingForm",
  initialState,
  reducers: {
    resetBookingForm(state) {
      state.isActiveSaveButton= false;
      state.isStartSave= false;
      state.isHasErrors= false;
      state.objectBlockValid= null;
      state.personBlockValid= null;
      state.contactBlockValid= null;
      state.commentBlockValid= null
    },
    setObjectBlockValid(state, action: PayloadAction<BookingBlockUpdate>) {
      state.objectBlockValid = action.payload;    
  },
    setPersonBlockValid(state, action: PayloadAction<BookingBlockUpdate>) {
      state.personBlockValid = action.payload;    
  },
    setContactBlockValid(state, action: PayloadAction<BookingBlockUpdate>) {
      state.contactBlockValid = action.payload;    
  },
    setCommentBlockValid(state, action: PayloadAction<BookingBlockUpdate>) {
      state.commentBlockValid = action.payload;    
  },
    startEdit(state) {
      state.isActiveSaveButton = true;    
  },
    startSave(state) {
      state.isStartSave = true;    
  },
    completeSave(state) {
      state.isStartSave = false;    
  },
    hasErrors(state) {
      state.isHasErrors = true;    
  },
    completeErrors(state) {
      state.isHasErrors = false;    
  },
    
}});

export const {  
  resetBookingForm, 
  setObjectBlockValid, 
  setPersonBlockValid,
  setContactBlockValid,
  setCommentBlockValid,
  startEdit,
  startSave,
  completeSave,
  hasErrors,
  completeErrors
} = BookingFormSlice.actions;

export default BookingFormSlice.reducer;

export const bookingFormName = BookingFormSlice.name;

export const BookingFormSelectors = {
  getBookingForm: (state: RootState) => state.bookingForm  
};
