import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BookingFormType, BookingType, UpdateBookingType } from "../../../redux/types/bookingTypes";
import { InputType } from "../../../utils/@globalTypes";
import Input from "../../Input";
import BookingSectionTitle from "../BookingSectionTitle";
import { BookingFormSelectors, completeSave, hasErrors, setCommentBlockValid, startEdit } from "../../../redux/reducers/bookingFormSlice";
import { COMMENT_REQ, TOTAL_INFO_ERROR } from "../../../utils/constants";

import styles from './BookingCommentBlock.module.scss';

type CommentblockProps ={
  windowType?:BookingFormType,
  booking?:BookingType  
} 

const BookingCommentBlock = ({
  windowType, 
  booking  
}:CommentblockProps) => {

  const [comment, setComment]= useState("");
  const [commentTouched, setCommentTouched]= useState(false);
  const [commentError, setCommentError] = useState("");
  const [isFocus, setFocus] = useState(false);

  const dispatch = useDispatch();
  const {
    isActiveSaveButton,
    isStartSave,
    isHasErrors,
    objectBlockValid,
    personBlockValid,
    contactBlockValid,
    commentBlockValid
  } = useSelector(BookingFormSelectors.getBookingForm)  
  
  useLayoutEffect(() => {   
    if(booking?.comment){      
      setComment(booking.comment);    
    }
  }, [booking]); 

  const commentView = !comment? "Дополнительных комментариев нет": comment;
  useEffect(()=> {
    if((commentTouched||booking?.comment!==comment)&&!isActiveSaveButton){  //проверяем на изменение данных
      dispatch(startEdit())
    }    
  }, [commentTouched, comment]);  

  useEffect(()=> {
    if(commentTouched){
      if (comment.length>550){
        setCommentError(TOTAL_INFO_ERROR);        
      }else {setCommentError("")}
    }    
  }, [commentTouched, comment]);  

  useEffect(()=> {
    if(isStartSave && windowType !== BookingFormType.view){
      tryValidBlock();      
    }
  }, [isStartSave]);

  useEffect(()=> {
    if(commentError){
      dispatch(setCommentBlockValid({
        isValid:false,
        update:{}
      }));      
    }
  }, [commentError]);

  const tryValidBlock=()=>{
    setCommentTouched(true);
    if(
      !commentError
    ){      
      const newCommentData:UpdateBookingType ={};      
      if(windowType === BookingFormType.edit&&booking){
        booking.comment!==comment&& (newCommentData.comment = comment)                
      }
      if(windowType === BookingFormType.new){         
        newCommentData.comment = comment          
      }          
      dispatch(setCommentBlockValid({isValid:true, update:newCommentData}))
    } else{      
      dispatch(setCommentBlockValid({isValid:false, update:{}}))
      dispatch(hasErrors())
    }
  }
  useEffect(() => {
    if(isHasErrors && objectBlockValid?.isValid && personBlockValid?.isValid&& contactBlockValid?.isValid&& !commentBlockValid?.isValid&& isStartSave){
      scrollToError();
    }
  }, [isHasErrors,objectBlockValid, personBlockValid, contactBlockValid, commentBlockValid, isStartSave]);

  const scrollToError = ()=>{
    console.log("старт скролла")
    if(commentError) {
        setFocus(true);
      // return
    } 
    dispatch(completeSave());
  }

  return (
    <div className={styles.wrap}>
      <BookingSectionTitle title="Дополнительные комментарии"/>
      <div className={styles.container}>
      {windowType===BookingFormType.view&&
            <div className={styles.commentView}>
              {commentView}
            </div>
          }
      {windowType!==BookingFormType.view&&
        <Input
          title="Комментарии"
          type={InputType.TEXTAREA}
          value={comment}
          placeholder="Введите текст"
          onChange={setComment}
          isBooking={true}
          className={"comment"}
          onBlur={setCommentTouched}
          errText={commentError}
          requirementsText={COMMENT_REQ}
          maxLength={550}          
        />}
      </div>
    </div>
  )
}

export default BookingCommentBlock ;