import React, { FC, ReactNode } from "react";
import styles from "./FormPage.module.scss";
import {
  CalendarIcon,
  CartIcon,
  CategoryIcon,
  DocumentIcon,
  FolderIcon,
  LogoIcon,
} from "../../assets/icons";
import Loader from "../../components/Loader";
import classNames from "classnames";

type FormPageProps = {
  children: ReactNode;
  pageTitle: string;
  description: string;
  rightContainerClassName?: string;
  isLoading: boolean;
};

const FormPage: FC<FormPageProps> = ({
  children,
  pageTitle,
  description,
  isLoading,
  rightContainerClassName,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.titleWrapper}>
          <LogoIcon />
          <h1>Система управления</h1>
        </div>
        <div className={styles.possWrapper}>
          <div className={styles.possItem}>
            <CartIcon />
            Управление онлайн-продажами
          </div>
          <div className={styles.possItem}>
            <CategoryIcon />
            Прямые бронирования
          </div>
          <div className={styles.possItem}>
            <CalendarIcon />
            Интерактивный календарь
          </div>
          <div className={styles.possItem}>
            <FolderIcon />
            Удобный контроль бизнеса
          </div>
          <div className={styles.possItem}>
            <DocumentIcon />
            Расширенная отчетность
          </div>
        </div>
      </div>
      <div className={classNames(styles.right, rightContainerClassName)}>
        <div className={styles.formWrapper}>
          {!isLoading ? (
            <>
              <h2>{pageTitle}</h2>
              <p className={styles.description}>{description}</p>
              {children}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormPage;
