import { useEffect, useLayoutEffect, useState, useRef, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingFormType, BookingType, UpdateBookingType } from '../../../redux/types/bookingTypes';
import { FieldTypes, InputModeType, InputType, TelephoneType } from '../../../utils/@globalTypes';
import Input from '../../Input';
import BookingSectionTitle from '../BookingSectionTitle';
import { setFieldErrorText } from '../../../utils/functions';
import { TELEGRAM_REQ } from '../../../utils/constants';
import { BookingFormSelectors, hasErrors, setContactBlockValid, startEdit } from "../../../redux/reducers/bookingFormSlice";
import styles from './BookingContactBlock.module.scss';
import IntlTelInput from '../../IntlTelInput';

type ContactblockProps ={
  windowType?:BookingFormType,
  booking?:BookingType   
} 

const BookingContactBlock = ({
    windowType, 
    booking      
  }:ContactblockProps) => {

  const dispatch = useDispatch();

  const {
    isActiveSaveButton,
    isStartSave,
    isHasErrors,
    objectBlockValid,
    personBlockValid,
    contactBlockValid
  } = useSelector(BookingFormSelectors.getBookingForm)  

  const [email, setEmail] = useState<string>("");
  const [telegram, setTelegram] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [telegramError, setTelegramError] = useState<string>("");
  const [telephoneError, setTelephoneError] = useState<string>("");

  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [telegramTouched, setTelegramTouched] = useState<boolean>(false);
  const [telephoneTouched, setTelephoneTouched] = useState<boolean>(false);

  const [isEmailFocus, setEmailFocus]= useState(false)
  const [isTelegramFocus, setTelegramFocus]= useState<boolean>(false)
  const [isPhoneFocus, setPhoneFocus]= useState<boolean>(false)

  const [telephone, setTelephone] = useState<TelephoneType>({
    country_code: "",
    phone_code: "",
    phone_number: "",
  });
  const [isTelephoneValid, setIsTelephoneValid] = useState(false);

  const inputRef = useRef<any>({});
  
  useLayoutEffect(() => {   
    if(booking){
      const {
        telegram,
        email                                 
      } = booking;  
      email&&setEmail(email);    
      telegram&&setTelegram(telegram)    
    }
  }, [booking]); 
  
  useEffect(()=> {     //проверяем на изменение данные
    if((emailTouched||telegramTouched||telephoneTouched)&& 
      (booking?.email!==email || 
        booking.telegram!==telegram || 
        booking.phone.phone_number!== telephone.phone_number
      )&&!isActiveSaveButton){
      dispatch(startEdit())
    }    
  }, [telephoneTouched, emailTouched, telegramTouched, telephone.phone_number]);  
 // Email

 useEffect(() => {
  return setFieldErrorText(emailTouched, email, setEmailError, FieldTypes.EMAIL, true, undefined, setEmailFocus);
}, [emailTouched, email]);


 // telegram
 useEffect(() => {
  setFieldErrorText(telegramTouched, telegram, setTelegramError, FieldTypes.TELEGRAM, false, undefined, setTelegramFocus);
}, [telegramTouched, telegram]);

//telephone
useEffect(() => {
  setFieldErrorText(
    telephoneTouched,
    telephone.phone_number,
    setTelephoneError,
    FieldTypes.TELEPHONE,
    true,
    isTelephoneValid,
    setPhoneFocus
  );
}, [telephoneTouched, telephone.phone_number, isTelephoneValid]);

useEffect(()=> {
  if(isStartSave && windowType !== BookingFormType.view){
    tryValidBlock();      
  }
}, [isStartSave]);

const tryValidBlock=()=>{
  setEmailTouched(true);
  setTelegramTouched(true);  
  setTelephoneTouched(true);
  if(
    !emailError&&
    !telegramError&&
    !telephoneError&&
    email.length > 0 
  ){
    const newContactData:UpdateBookingType ={};
      if(windowType === BookingFormType.edit&&booking){        
        booking.email!==email&& (newContactData.email = email);
        booking.telegram!==telegram && (newContactData.telegram = telegram);
        JSON.stringify(booking.phone)!== JSON.stringify(telephone) &&
        (newContactData.phone = {
          country_code: telephone.country_code,
          phone_code: telephone.phone_code,
          phone_number: telephone.phone_number
        }); 
        return dispatch(setContactBlockValid({isValid:true, update:newContactData}))
      }
      if(windowType === BookingFormType.new){
        newContactData.email = email;
        newContactData.telegram = telegram;
        newContactData.phone = {
          country_code: telephone.country_code,
          phone_code: telephone.phone_code,
          phone_number: telephone.phone_number
        }
        dispatch(setContactBlockValid({isValid:true, update:newContactData}))
      } 
    } else{      
          dispatch(setContactBlockValid({isValid:false, update:{}}));
          dispatch(hasErrors())      
    }  
  }

useEffect(() => {
  if(isHasErrors && objectBlockValid?.isValid&&personBlockValid?.isValid&&!contactBlockValid?.isValid&&isStartSave){
    scrollToError();
  }
}, [isHasErrors,objectBlockValid?.isValid, personBlockValid?.isValid, contactBlockValid?.isValid, isStartSave]);

const scrollToError = ()=>{
  if(emailError) {
      setEmailFocus(true);
    return
  } else if(telegramError){      
      setTelegramFocus(true)
    return
  } else if(telephoneError){       
      setPhoneFocus(true)
  }
}
  return (
    <div className={styles.wrap} id='wrap'>
      <BookingSectionTitle title="Контакты"/>
      <div className={styles.container}>
        <div className={styles.column}>
          <div>
            <Input
              id="email"
              value={email}
              title={windowType===BookingFormType.view ? "Электронная почта" : "Электронная почта*"}
              name="email"
              placeholder="Введите электронную почту"
              containerClassName={styles.inputСontainer}
              className= {styles.booking}
              type={InputType.EMAIL}
              inputMode={InputModeType.EMAIL}
              errText={emailError}
              onChange={setEmail}
              onBlur={setEmailTouched}
              banSymbols={/\s/g}
              disabled={windowType===BookingFormType.view ? true : false}
              isBooking={true}
              isFocus={isEmailFocus}              
            />
          </div>
          <div>
            <Input
              id="telegram"
              value={telegram}
              title="Телеграм"
              name="telegram"
              placeholder={windowType===BookingFormType.view ? "-" : "@nickname"}
              containerClassName={styles.inputСontainer}
              className= {styles.booking}
              type={InputType.TEXT}
              inputMode={InputModeType.TEXT}
              errText={telegramError}
              onChange={setTelegram}
              onBlur={setTelegramTouched}
              banSymbols={/\s/g}
              requirementsText={windowType===BookingFormType.view ? undefined: TELEGRAM_REQ}
              disabled={windowType===BookingFormType.view ? true : false}
              isBooking={true}    
              isFocus={isTelegramFocus}         
            />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <IntlTelInput
            id="phone"
            title={windowType===BookingFormType.view ? "Мобильный телефон" : "Мобильный телефон*"}
            value={telephone}
            placeholder="Введите номер"
            onChange={setTelephone}
            errText={telephoneError}
            setError={setTelephoneError}
            onBlur={setTelephoneTouched}
            setIsValidate={setIsTelephoneValid}
            isBooking={true}
            disabled={windowType===BookingFormType.view ? true : false}
            isFocus={isPhoneFocus} 
            preloadedData={booking?.phone}   // первоначальная загрузка номера телефона в компонент
          />
        </div>
      </div>
    </div>
  )
}

export default BookingContactBlock ;