import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {subMonths, addYears, format} from 'date-fns';
import { ButtonType, ModalWindowTypes } from '../../../utils/@globalTypes';
import { setModalWindowData } from '../../../redux/reducers/pageSlice';
import Button from '../../Button';
import SelectMultipleCustom from '../../SelectMultipleCustom';
import { FilterIcon, HomeIcon, InfoIcon } from '../../../assets/icons';
import ListHeader from '../ListHeader';
import ListItem from '../ListItem';
import FilterBanner from '../FilterBanner';
import Calendar from '../../Calendar';
import { BookingSelectors } from '../../../redux/reducers/bookingSlice';
import { createObjectList } from '../../Booking/functions';

import { BookingType, SelectState } from '../../../redux/types/bookingTypes';
import EmptyState from '../../EmptyState';

import styles from './BookingListWrap.module.scss';
import StatusInfo from '../StatusInfo/StatusInfo';
import { useClickOutside } from '../../../utils/functions';
import MobileFilterPanel from '../MobileFilterPanel';

const BookingListWrap = () => {
  const dispatch = useDispatch();
  const objects = useSelector(BookingSelectors.getChessObjects);
  const bookingArray = useSelector(BookingSelectors.getBookingList);  

  const handlerNewBooking=()=>{
    dispatch(setModalWindowData({type: ModalWindowTypes.CREATE_RESERVATION}))    
  }
  const [filterNew, setFilterNew] = useState<boolean>(false);
  const [filterConfirm, setFilterConfirm] = useState<boolean>(false);
  const [filterAwait, setFilterAwait] = useState<boolean>(false);
  const [filterLine, setFilterLine] = useState<boolean>(false);
  const [filterCanceled, setFilterCanceled] = useState<boolean>(false);

  const [initialList, setInitialList] = useState<BookingType[]>([]);
  const [filteredList, setFilteredList]= useState<BookingType[]>([]);

  const [filterNames, setFilterNames] = useState<{title:any, close:any, type?:string}[]>([])
  const [isOpenFilterWindow, setisOpenFilterWindow] = useState<boolean>(false);

  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState<boolean>(true)
  const [isStatusInfoOpen, setisStatusInfoOpen] = useState<boolean>(false)
  
  const menuRef = useRef(null);
  const btnRef = useRef(null);

  useClickOutside(menuRef, btnRef, () => setisStatusInfoOpen(false));  

  const handlerFilterNew= ()=>{setFilterNew((prev)=> !prev)};
  const handlerFilterConfirm= ()=>{setFilterConfirm((prev)=> !prev)};
  const handlerFilterAwait= ()=>{setFilterAwait((prev)=> !prev)};
  const handlerFilterLine= ()=>{setFilterLine((prev)=> !prev)};
  const handlerFilterCanceled= ()=>{setFilterCanceled((prev)=> !prev)};

  const [select, setSelect] = useState<SelectState[]>([])   //список объектов для селекта и признак фильтрации
  
  const objectList = objects?createObjectList(objects):[];

  const today = new Date();
  const minDate = subMonths(today, 6);
  const maxDate = addYears(today, 1);
  const [dateRange, setDateRange] = useState([undefined, undefined]);
  const [startDate, endDate] = dateRange;

  const calendarPlaceholder = `${format(minDate, 'dd.MM.yyyy')} - ${format(maxDate, 'dd.MM.yyyy')}`

  const initialSelect = ()=> {   // начальный стейт для списка объектов
    const start:SelectState[] = []
    objectList&&objectList.forEach((item)=>{
      start.push({id:item.value, checked:false})            
    })
    setSelect(start)
  }
  

  useEffect(()=>{            
    initialSelect();
  },[objects])

  const deleteAllselect =(e:React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    e.stopPropagation();
    initialSelect();
  }

  const clearCalendar =(e:React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    e.stopPropagation();
    setDateRange([undefined, undefined])
  }
  
  useEffect(()=> {
      if (bookingArray&&bookingArray.length>0){
        const sortArray =bookingArray.map(Item=> Item).sort((a,b)=>{  //сортируем по дате создания
        const f = new Date(a.created_at).getTime();  
        const s = new Date(b.created_at).getTime();        
      return s-f
      }) 
      
      setInitialList(sortArray);
      setFilteredList(sortArray);
    }
  }, [bookingArray]);

  const handlerSelect = (id:string) => {   //   обработка чекбоксов в списке объектов
    const current=select.findIndex(item=> item.id===id)
    
      if(current>-1){
       setSelect(prevState=> {
        const newSelect = [...prevState]    
        newSelect[current].checked=!newSelect[current].checked     
        return(
          newSelect
        )
       })
      }   
  }  
  
  
  useEffect(()=> {
    
    const isHadToFilterforObject = select.find(el=> el.checked===true)
    const isHadToFilterforDate = !!startDate;
    
    let result:BookingType[]=[]
    if(filterNew || filterConfirm || filterAwait|| filterLine|| filterCanceled ){  // фильтрация по статусам
      
      result=initialList.filter(item=>(
        (filterNew&&item.status==="new") ||
        (filterConfirm&&item.status==="cfm") ||
        (filterAwait&&item.status==="awt") ||
        (filterLine&&item.status==="que") ||
        (filterCanceled&&item.status==="cld")
      ))       
    } else {result =[...initialList]}

    if (isHadToFilterforObject){      //фильтрация по объектам
      
      result=result.filter(el=>{
        return select.some((slct) => {          
            return slct.checked && slct.id=== el.rental_object.toString()
          })
        })
    }
    
    if (isHadToFilterforDate){   //фильтрация по датам
        result=result.filter(el=> new Date(el.check_in_date)> new Date(startDate) || new Date(el.check_out_date)> new Date(startDate))      
    }
    if (!!endDate){
      result=result.filter(el=> new Date(el.check_in_date)< new Date(endDate))   
    }
    setFilteredList(result)   

  }, [filterNew, filterConfirm, filterAwait, filterLine, filterCanceled, initialList, select, startDate, endDate]);  

  useEffect(()=> {  
    if(filterNames.length>0 && isResetButtonDisabled){
      setIsResetButtonDisabled(false)
    } 
    if (filterNames.length===0 && !isResetButtonDisabled){
      setIsResetButtonDisabled(true)
    }
    
  }, [filterNames]);

   //создаем ярлычки для разных признаков выбора по статусам

 /////   СТАРАЯ ВЕРСИЯ

  // useEffect(()=> {   
  //   const newFilterNames =[...filterNames]
  //   if(filterNew){
  //     const item = {
  //       title:"Новая бронь",
  //       close:setFilterNew        
  //     }
  //     newFilterNames.push(item)
  //     setFilterNames((prev)=>newFilterNames)
  //   } 
  //   if(!filterNew){
  //     const canceledFilter=newFilterNames.filter(item=> item.title!=="Новая бронь")
  //     setFilterNames((prev)=>canceledFilter)
  //   }
    
  // }, [filterNew]);

  // useEffect(()=> {
  //   const newFilterNames =[...filterNames]
  //   if(filterConfirm){
  //     const item = {
  //       title:"Бронь подтверждена",
  //       close:setFilterConfirm    
  //     }
  //     newFilterNames.push(item)
  //     setFilterNames((prev)=>newFilterNames)
  //   } 
  //   if(!filterConfirm){
  //     const canceledFilter=newFilterNames.filter(item=> item.title!=="Бронь подтверждена")
  //     setFilterNames((prev)=>canceledFilter)
  //   }
    
  // }, [filterConfirm]);

  // useEffect(()=> {
  //   const newFilterNames =[...filterNames]
  //   if(filterAwait){
  //     const item = {
  //       title:"Ожидает подтверждения",
  //       close:setFilterAwait   
  //     }
  //     newFilterNames.push(item)
  //     setFilterNames((prev)=>newFilterNames)
  //   } 
  //   if(!filterAwait){
  //     const canceledFilter=newFilterNames.filter(item=> item.title!=="Ожидает подтверждения")
  //     setFilterNames((prev)=>canceledFilter)
  //   }
    
  // }, [filterAwait]);

  // useEffect(()=> {
  //   const newFilterNames =[...filterNames]
  //   if(filterLine){
  //     const item = {
  //       title:"Бронь в очереди",
  //       close:setFilterLine   
  //     }
  //     newFilterNames.push(item)
  //     setFilterNames((prev)=>newFilterNames)
  //   } 
  //   if(!filterLine){
  //     const canceledFilter=newFilterNames.filter(item=> item.title!=="Бронь в очереди")
  //     setFilterNames((prev)=>canceledFilter)
  //   }
    
  // }, [filterLine]);

  // useEffect(()=> {
  //   const newFilterNames =[...filterNames]
  //   if(filterCanceled){
  //     const item = {
  //       title:"Бронь отменена",
  //       close:setFilterCanceled 
  //     }
  //     newFilterNames.push(item)
  //     setFilterNames((prev)=>newFilterNames)
  //   } 
  //   if(!filterCanceled){
  //     const canceledFilter=newFilterNames.filter(item=> item.title!=="Бронь отменена")
  //     setFilterNames((prev)=>canceledFilter)
  //   }
    
  // }, [filterCanceled]);

  useEffect(()=> {
    let newFilterNames =[...filterNames]
      const currentNew = newFilterNames.findIndex(item=> item.title==="Новая бронь")
      if(filterNew&&currentNew<0){
        const item = {
                title:"Новая бронь",
                close:setFilterNew        
              }
              newFilterNames.push(item)
      }
      if(!filterNew&&currentNew>-1){
        newFilterNames=newFilterNames.filter(item=> item.title!=="Новая бронь")
      }

      const currentAwait = newFilterNames.findIndex(item=> item.title==="Ожидает подтверждения")
      if(filterAwait&&currentAwait<0){
        const item = {
                title:"Ожидает подтверждения",
                close:setFilterAwait        
              }
              newFilterNames.push(item)
      }
      if(!filterAwait&&currentAwait>-1){
        newFilterNames=newFilterNames.filter(item=> item.title!=="Ожидает подтверждения")
      }
      const currentCanceled = newFilterNames.findIndex(item=> item.title==="Бронь отменена")
      if(filterCanceled&&currentCanceled<0){
        const item = {
                title:"Бронь отменена",
                close:setFilterCanceled        
              }
              newFilterNames.push(item)
      }
      if(!filterCanceled&&currentCanceled>-1){
        newFilterNames=newFilterNames.filter(item=> item.title!=="Бронь отменена")
      }
      const currentLine = newFilterNames.findIndex(item=> item.title==="Бронь в очереди")
      if(filterLine&&currentLine<0){
        const item = {
                title:"Бронь в очереди",
                close:setFilterLine        
              }
              newFilterNames.push(item)
      }
      if(!filterLine&&currentLine>-1){
        newFilterNames=newFilterNames.filter(item=> item.title!=="Бронь в очереди")
      }
      const currentConfirm = newFilterNames.findIndex(item=> item.title==="Бронь подтверждена")
      if(filterConfirm&&currentConfirm<0){
        const item = {
                title:"Бронь подтверждена",
                close:setFilterConfirm        
              }
              newFilterNames.push(item)
      }
      if(!filterConfirm&&currentConfirm>-1){
        newFilterNames=newFilterNames.filter(item=> item.title!=="Бронь подтверждена")
      }
      console.log(newFilterNames)
      setFilterNames((prev)=>newFilterNames)

  }, [filterNew, filterAwait, filterCanceled, filterLine, filterConfirm]);
   
  const handlerObjectClose=(id:string)=>{
    const current=select.findIndex(item=> item.id===id)    
      if(current>-1){
       setSelect(prevState=> {
        const newSelect = [...prevState]    
        newSelect[current].checked=false     
        return(
          newSelect
        )
       })
      }   
  }

  useEffect(()=> {    
    const newFilterNames =[...filterNames];
    select.forEach(item=>{ 
      const obj=objectList?.find(obj=> obj.value===item.id)
      const check = filterNames.find(chk=> chk.title===obj?.label)
      if(item.checked && !check ){            
        const objTitle=obj? (obj?.label) : ""
        const newNames = {
          title:objTitle,
          close:()=>handlerObjectClose(item.id)
        }
        newFilterNames.push(newNames) 
        setFilterNames((prev)=> {return newFilterNames})       
      }
      if(!item.checked && check){
        
        setFilterNames((prev)=> {return prev.filter(ftName=> ftName.title !== obj?.label)})
      }    
    })  
  },[select]); 

  useEffect(()=> {    
    const newFilterNames =[...filterNames]
          
    if(startDate&& !!!endDate){
      const res = newFilterNames.filter(el=> el.type!=="date")
      const item = {
        title:`c ${format(startDate, "dd.MM.yyyy")}`,
        close:()=>setDateRange([undefined, undefined]),
        type:"date"
      }
      res.push(item)
      setFilterNames(res)
    } else if (startDate&& endDate){
      const res = newFilterNames.filter(el=> el.type!=="date")
      const item = {
        title:`c ${format(startDate, "dd.MM.yyyy")} по ${format(endDate, "dd.MM.yyyy")}`,
        close:()=>setDateRange([undefined, undefined]),
        type:"date"
      }
      res.push(item)
      setFilterNames(res)
    } else {setFilterNames((prev)=> {return prev.filter(el=> el.type!=="date")})}
    
  }, [startDate, endDate]);

  const countItem:number=filteredList.length;
  const title = countItem%10===1 ? "бронь"
  : countItem<5&&countItem>0  ? "брони" 
  : (countItem%10 === 2|| countItem%10 ===3|| countItem%10 === 4)&&countItem>20 ? "брони": "броней"

  const list = filteredList.length>0?
    filteredList.map((item, index)=>{    
    return (
    <React.Fragment key={index}>
      <ListItem booking={item} />
    </React.Fragment>)
  }): initialList.length> 0? (
      <div className={styles.empty}>
        <EmptyState
          text={`К сожалению, по Вашему запросу не нашлось результатов. Попробуйте изменить критерии поиска`}
          icon={HomeIcon({})}
          className='bookingList'
        />
      </div>)
    :   <div className={styles.empty}>
          <EmptyState
            text={`Список броней пока пуст. Вы еще не добавили ни одной брони`}
            icon={HomeIcon({})}
            className='bookingList'
          /> 
        </div>
         
  const handlerResetFilters = ()=> {  // сброс всех фильтров
    setFilterNew(false);
    setFilterConfirm(false);
    setFilterAwait(false);
    setFilterLine(false);
    setFilterCanceled(false);
    setFilteredList(initialList)
    setFilterNames([])
    initialSelect();    
    setDateRange([undefined, undefined]);
  }
  
  const displayFilter = filterNames.length>0?
  filterNames.map(item=>{
    return(
      <FilterBanner
        key={item.title}
        title={item.title}
        handlerClose={item.close}
      />
    )
  }) : ""
  
  
  const openStatusInfo =() => {
    setisStatusInfoOpen((prev)=> !prev)
  }
  const closeWindow =() => {
    setisStatusInfoOpen(false)
  }
  
  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <div className={styles.btnNew}>
          <Button
              title="Новая бронь"
              onClick={handlerNewBooking}
              type={ButtonType.PRIMARY_SMALL}
              className="bookingButton"
          />
        </div>  
        
        <ListHeader
          handlerFilterNew={handlerFilterNew}
          handlerFilterConfirm={handlerFilterConfirm}
          handlerFilterAwait={handlerFilterAwait}
          handlerFilterLine={handlerFilterLine}
          handlerFilterCanceled={handlerFilterCanceled}
          isFilterNew={filterNew}
          isFilterConfirm={filterConfirm}
          isFilterAwait={filterAwait}
          isFilterLine={filterLine}
          isFilterCanceled={filterCanceled}
        />
      </div>  

      <div className={styles.headerMobile}>
        <div className={styles.leftMobHead}>
          <div className={styles.btnNew}>
            <Button
                title="Новая бронь"
                onClick={handlerNewBooking}
                type={ButtonType.PRIMARY_SMALL}
                className="mobileButton"                
            />
          </div>
          <div className={styles.btnMobile}>
            <Button
              type={ButtonType.PRIMARY_SMALL}
              title="+"
              className="smallBookingList"
              wrapperClassName="bookingList"
              windowClassName="mobileButton"
              onClick={handlerNewBooking}         
            />  
          </div>
          <Button
            type={ButtonType.SMALL}
            title={<FilterIcon />}
            className="deleteBookingList"
            wrapperClassName="bookingList"
            windowClassName="bookingConfirm"
            onClick={()=>setisOpenFilterWindow(true)}           
          />   
        </div>
        <div className={styles.rightMobHead} onClick={openStatusInfo} ref={menuRef}>
          <InfoIcon/>
          <p className={styles.statusNote}>Статусы броней</p>
          
        </div>
        {isStatusInfoOpen && 
            <div className={styles.statusWindow} ref={btnRef}>
              <StatusInfo handlerClick={closeWindow}/>
            </div>}
      </div> 

      <main className={styles.main}>   
        <div className={styles.leftColumn}>
          <div className={styles.stickyWrap}>
            <div className={styles.title}>
              Выберите параметры поиска
            </div>
            <div className={styles.selectPanel}>
              <div className={styles.objectSelect}>
                <h5 className={styles.selectHeader}>
                  Объект
                </h5>
                <SelectMultipleCustom
                  optionList={objectList}
                  select={select}
                  handler={handlerSelect}
                  deleteAll={deleteAllselect}
                />
              </div>
              <div className={styles.dateSelect}>
                <h5 className={styles.selectHeader}>
                  Период брони
                </h5>                
                  <Calendar
                    selectedDate={today}
                    maxDate={maxDate}
                    minDate={minDate}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    inputClassname='bookingList'
                    placeholderText={calendarPlaceholder}     
                    clearCalendar={clearCalendar}         
                    calendarClassName="bookingListCalendar"
                  />                
              </div> 
            
              <div className={styles.btnReset}>
                <Button
                  title="Сбросить фильтры"
                  type={ButtonType.PRIMARY_SMALL}
                  className={styles.bookingButton}
                  disabled={isResetButtonDisabled}
                  onClick={handlerResetFilters}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightColumn}>
        {!isResetButtonDisabled && 
          <div className={styles.filterScreen}>
            <div className={styles.count}> 
              {`Найдено ${countItem} ${title}`}            
            </div>
            {displayFilter}
          </div>} 
          <div className={styles.list}>
            {list}
          </div>
        </div>
      </main>
      {isOpenFilterWindow&&
        <div className={styles.shadowFilter}>

        </div>
      }        
      {isOpenFilterWindow&&
        <MobileFilterPanel
          close ={setisOpenFilterWindow}
          startCalendar={startDate}
          endCalendar={endDate}
          objectList ={objectList}
          setSelectObject={setSelect}
          selectObject ={select}
          statusNew={filterNew}
          setStatusNew ={setFilterNew}
          statusConfirm={filterConfirm}
          setStatusConfirm ={setFilterConfirm}
          statusAwait={filterAwait}
          setStatusAwait ={setFilterAwait}
          statusLine={filterLine}
          setStatusLine ={setFilterLine}
          statusCanceled={filterCanceled}
          setStatusCanceled ={setFilterCanceled}
          setRangeCalendar = {setDateRange}
        />
      }        
    </section>
  )
}

export default BookingListWrap;

