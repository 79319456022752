import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalWindowData, setPageData } from "../../../redux/reducers/pageSlice";
import moment from "moment";
import 'moment/locale/ru';
import classNames from "classnames";
import Calendar from "../../../components/Calendar";
import Button from "../../../components/Button";
import { ButtonType, ModalWindowTypes } from "../../../utils/@globalTypes";
import CheckerboardTable from "../../../components/CheckerboardTable";
import { 
  ArrowSingle, 
  ArrowDouble,
  AwaitBookingIcon,
  ConfirmBookingIcon 
} from "../../../assets/icons";
import styles from "./Checkerboard.module.scss";
import { BookingSelectors } from "../../../redux/reducers/bookingSlice";


const Checkerboard = () => {
  const dispatch = useDispatch();
  moment.locale('ru');
  
  const objects = useSelector(BookingSelectors.getChessObjects);
  
  const today= moment().startOf('day');
  const startDate=today.clone().subtract(6,'month')
  const endDate=today.clone().add(12,'month')

  const createPeriod =(today:moment.Moment)=> {
    
    const period:moment.Moment[]=[]
        
    for(let i=startDate; i<=endDate; i=i.clone().add(1,"day")){      
      period.push(i)
    }
    return period
  }
  const period= useMemo(()=>createPeriod(today),[today])

  const itemWidth = 100;   // Объявляем ширину ячейки таблицы, используется в расчетах и стилях
 
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [initialDay, setInitialDay]=useState(today);
  const [scrollPosition, setScrollPosition] = useState(18000);
  const [isForceUpdateScroll, setIsForceUpdateScroll] = useState(false)   //принудительный update скролла, если дата на календаре не менялась и надо к ней вернуться

  const minDate = startDate.toDate();  //ограничительные даты для календаря
  const maxDate = endDate.toDate();
 
 const updateScrollForDate = (date:moment.Moment) => {          //обновление скролла после выбора дат на календаре
    const indexDate = period.findIndex((item)=>item.startOf('day').unix()===date.startOf('day').unix())
    const scroll = (indexDate*itemWidth) - itemWidth*2
    setScrollPosition(scroll)
  }
  useEffect(()=>{
    updateScrollForDate(initialDay)
    setSelectedDate(initialDay.toDate())
  },[initialDay])

  const handlerPrevMonth=()=>{
    setInitialDay(prevState=> {
      return(
      prevState.clone().subtract(1, 'month')
      )}
    )
    setIsForceUpdateScroll(false)
  }

  const handlerNextMonth=()=>{
    setInitialDay(prevState=> {
      return(
      prevState.clone().add(1, 'month')
      )}
    )
    setIsForceUpdateScroll(false)
  }

  const handlerPrevWeek=()=>{
    setInitialDay(prevState=> {
      return(
      prevState.clone().subtract(7, 'day')
      )}
    )
    setIsForceUpdateScroll(false)
  }

  const handlerNextWeek=()=>{
    setInitialDay(prevState=> {
      return(
      prevState.clone().add(7, 'day')
    )}
  )
  setIsForceUpdateScroll(false)
}

  const handlerToday=()=>{
    setInitialDay(today)
    setIsForceUpdateScroll(true)    
  }
  const handlerNewBooking=()=>{
    dispatch(setModalWindowData({type: ModalWindowTypes.CREATE_RESERVATION}))    
  }

  const handlerDatepicker =(date:Date)=> {
    if (date instanceof Date){
      
    setSelectedDate(date);
    setInitialDay(moment(date))

    }else{
      setSelectedDate(new Date());
      setInitialDay(today)
    }
  }

  const cancelForceUpdate = ()=> {
    setIsForceUpdateScroll(false)
  }

  return (
    <section className={styles.container}>
      <div className={styles.buttonPanel}>
        <div className={styles.leftWrapper}>
          <div className={styles.calendarButton}> 
            <div className={styles.prev} onClick={handlerPrevMonth}>
              <ArrowDouble/>
              <div className={styles.help}>Предыдущий месяц</div>
            </div>            
            <div className={styles.prev} onClick={handlerPrevWeek}>
              <ArrowSingle/>
              <div className={styles.help}>Предыдущая неделя</div>              
            </div>
          </div>
          <Calendar 
            selectedDate={selectedDate}
            onChange={handlerDatepicker}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText="Введите дату"
          />
          <div className={styles.calendarButton}> 
            <div className={styles.next} onClick={handlerNextWeek}>
              <ArrowSingle/>
              <div className={styles.help}>Следующая неделя</div>
            </div>
            <div className={styles.next} onClick={handlerNextMonth}>
              <ArrowDouble/>
              <div className={styles.help}>Следующий месяц</div>
            </div>
          </div>
          <Button
            title="Сегодня"
            onClick={handlerToday}
            type={ButtonType.PRIMARY_SMALL}
            className={styles.todayButton}
          />
        </div>
        <div className={styles.helpPanel}>
          <div> <AwaitBookingIcon width="20" height="20"/> Ожидает подтверждения </div>
          <div> <ConfirmBookingIcon width="20" height="20"/> Бронь подтверждена </div>
        </div>
        <div className={styles.rightWrapper}>        
          <Button
            title="Новая бронь"
            onClick={handlerNewBooking}
            type={ButtonType.PRIMARY_SMALL}
            className={styles.bookingButton}
          />
        </div>        
      </div>
      <div className={styles.describe}>Отображаются брони со статусами: "подтверждена" и "ожидает подтверждения"</div>
      {objects&&<CheckerboardTable 
        period={period} 
        today={today} 
        scrollPosition ={scrollPosition} 
        itemWidth={itemWidth} 
        isForceUpdate={isForceUpdateScroll}
        cancelForce = {cancelForceUpdate}
        objects= {objects}
        selectedDate = {selectedDate}
      />}
      
    </section>
  );
};

export default Checkerboard;
