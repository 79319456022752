import React from "react";

export const ConfirmBookingIcon = ({ width = "21", height = "21", stroke = "#42AC4D"}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_8700_49742" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.00195 10C1.00195 7.61305 1.95016 5.32387 3.63799 3.63604C5.32582 1.94821 7.615 1 10.002 1C12.3889 1 14.6781 1.94821 16.3659 3.63604C18.0537 5.32387 19.002 7.61305 19.002 10C19.002 12.3869 18.0537 14.6761 16.3659 16.364C14.6781 18.0518 12.3889 19 10.002 19C7.615 19 5.32582 18.0518 3.63799 16.364C1.95016 14.6761 1.00195 12.3869 1.00195 10Z"/>
      </mask>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.00195 10C1.00195 7.61305 1.95016 5.32387 3.63799 3.63604C5.32582 1.94821 7.615 1 10.002 1C12.3889 1 14.6781 1.94821 16.3659 3.63604C18.0537 5.32387 19.002 7.61305 19.002 10C19.002 12.3869 18.0537 14.6761 16.3659 16.364C14.6781 18.0518 12.3889 19 10.002 19C7.615 19 5.32582 18.0518 3.63799 16.364C1.95016 14.6761 1.00195 12.3869 1.00195 10Z" stroke={stroke} strokeWidth="1.5" mask="url(#path-1-inside-1_8700_49742)"/>
      <mask id="path-2-inside-2_8700_49742" fill="white">
        <path d="M14.3928 6.56166L8.39045 12.1777L5.61305 9.57846C5.37125 9.35226 4.99205 9.36486 4.76525 9.60666C4.53905 9.84846 4.55165 10.2283 4.79345 10.4545L7.98125 13.4371C8.09645 13.5451 8.24405 13.5991 8.39105 13.5991C8.53805 13.5991 8.68565 13.5451 8.80085 13.4371L15.2136 7.43706C15.4554 7.21086 15.468 6.83106 15.2418 6.58926C15.0144 6.34746 14.634 6.33486 14.3928 6.56166Z"/>
      </mask>
      <path d="M14.3928 6.56166L8.39045 12.1777L5.61305 9.57846C5.37125 9.35226 4.99205 9.36486 4.76525 9.60666C4.53905 9.84846 4.55165 10.2283 4.79345 10.4545L7.98125 13.4371C8.09645 13.5451 8.24405 13.5991 8.39105 13.5991C8.53805 13.5991 8.68565 13.5451 8.80085 13.4371L15.2136 7.43706C15.4554 7.21086 15.468 6.83106 15.2418 6.58926C15.0144 6.34746 14.634 6.33486 14.3928 6.56166Z" fill={stroke} stroke={stroke} strokeWidth="1.5" mask="url(#path-2-inside-2_8700_49742)"/>
    </svg> );
};
