import { ReactNode } from "react";
import { ImageType } from "react-images-uploading";
import { RentalObjectFeature } from "../redux/types/rentalObjectTypes";

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  PRIMARY_SMALL,
  SECONDARY_SMALL,
  SMALL,
  ERROR_SMALL,
  LINK,
}

export enum InputType {
  TEXT = "text",
  EMAIL = "email",
  TELEPHONE = "tel",
  PASSWORD = "password",
  // INTL_TELEPHONE = "intlTel",
  // SELECT = "select",
  TEXTAREA = "textarea",
  MASK = "mask",
}

export enum InputModeType {
  NONE = "none",
  TEXT = "text",
  EMAIL = "email",
  TELEPHONE = "tel",
  NUMERIC = "numeric",
  DECIMAL = "decimal",
  SEARCH = "search",
  URL = "url",
}

export enum ModalWindowTypes {
  CREATE_MAIN_OBJECT = "create-main-object",
  CREATE_RENTAL_OBJECT = "create-rental-object",
  ACTIVATE_ACCOUNT = "activate-account",
  CREATE_RESERVATION = "create-reservation",
  SHOW_RESERVATION = "show-reservation",
  CLOSE = "close",
}

export type OptionType = {
  value: string;
  label: string | ReactNode;
  color?: string;
};

export type OptionsListType = OptionType[];

export enum TabsNames {
  BASIC_SETTINGS = "basic-settings",
  OBJECT_EDITING = "rental-objects",
  CONTROL_SETTINGS = "control-settings",
  EMPLOYEE_MANAGEMENT = "employee-management",
  RENTAL_BASIC_SETTINGS = "rental-basic-settings",
  PLACEMENT_SETTINGS = "placement-settings",
  PRICES = "prices-seasons",
  BOOKING_SETTINGS = "booking-settings",
  PERSON_SETTINGS = "person-settings",
  ORGANIZATION_SETTINGS = "organization-settings",
}

export type RadioButtonTypes = [
  // Olya
  { first: string },
  { second: string }
];

export enum LoaderTypes {
  MAIN_OBJECTS_PAGE = "main-objects-page",
  OBJECT_EDITING_PAGE = "object-editing-page",
  RENTAL_OBJECT_EDITING_PAGE = "rental-object-editing-page",
  SETTINGS_PAGE = "settings-page",
  SIGN_IN_PAGE = "sign-in-page",
  SIGN_UP_PAGE = "sign-up-page",
  SIGN_UP_STAFF_PAGE = "sign-up-staff-page",
  ACTIVATE_ACCOUNT_POPUP_1 = "activate-account-popup-1",
  ACTIVATE_ACCOUNT_POPUP_2 = "activate-account-popup-2",
  CREATE_OBJECT_POPUP = "create-object-popup",
  CREATE_RENTAL_OBJECT_POPUP = "create-rental-object-popup",
  BOOKING_PAGE = "booking",
}

export enum FieldTypes {
  FIRST_NAME = "name",
  LAST_NAME = "surname",
  MIDDLE_NAME = "middlename",
  EMAIL = "email",
  OBJECT_NAME = "objectName",
  OBJECT_TYPE = "objectType",
  URL = "url",
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirmPassword",
  TELEPHONE = "telephone",
  TELEGRAM = "telegram",
  POSITION = "position",
  MAIN_OBJECT_PHOTO = "mainObjectPhoto",
  MAIN_OBJECT_BOOKING_PHOTO = "mainObjectBookingPhoto",
  PROFILE_PHOTO = "profilePhoto",
  REGION = "region",
  LOCALITY = "locality",
  ADDRESS = "address",
  IND_TAXPAYER_NUMBER = "indTaxpayerNumber",
  ENT_TAXPAYER_NUMBER = "entTaxpayerNumber",
  ENTITY_NAME = "entityName",
  CODE = "code",
  COUNTRY = "country",
  PERMISSION = "permission",
  RENTAL_DESCRIPTION = "rentalDescription",
  FEATURE_NAME = "featureName",
  ROOM_NAME = "roomName",
  RENTAL_OBJECT = "rentalObject",
  CERTAIN_DAY_PRICE = "ceratinDayPrice",
  DEFAULT = "default",
}

export enum UserStatusTypes {
  OWNER = "owner",
  STAFF = "staff",
  ADMIN = "admin",
}

export enum MessageTypes {
  POSITIVE,
  ERROR,
  WARNING,
}

export enum ObjectTypes {
  FARMSTEAD = "Усадьба/Агроусадьба",
  GUEST_HOUSE = "Гостевой дом",
  APARTMENTS = "Квартиры/Апартаменты",
  CAMPING = "Кемпинг",
  GLAMPING = "Глэмпинг",
  MINI_HOTEL = "Мини-отель",
  TOURIST_COMPLEX = "Туристический комплекс",
  RECREATION_CENTER = "База отдыха",
}

export enum PermissionTypes {
  ADMIN = "admin",
  MANAGER = "manager",
  PERSONAL = "personal",
}

export type TimeDataType = {
  days: {
    char1: string;
    char2: string;
  };
  hours: {
    char1: string;
    char2: string;
  };
  minutes: {
    char1: string;
    char2: string;
  };
  seconds: {
    char1: string;
    char2: string;
  };
};

export type TelephoneType = {
  country_code: string;
  phone_code: string;
  phone_number: string;
};

export type AddressType = {
  region: string;
  locality: string;
  address: string;
};

export type LocalFeatureType = {
  isNew: boolean;
  isValid: boolean;
  feature: RentalObjectFeature;
};

export type FormattedImageListType = {
  id: number;
  value: {
    image: any;
    error: string | null;
    isNew: boolean;
  };
}[];
